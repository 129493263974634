export default {
  add: 'Add product to library',
  attachSelected: 'Attach selected products',
  my: 'My Products',
  plural: 'Products',
  singular: 'Product',
  addProduct: 'Add product',
  attach: 'Attach products',
  category: 'Product category',
  name: 'Product name',
  search: 'Search products',
  selected: 'Selected products',
  viewAll: 'View all products',
  categories: {
    homeDecor: 'Home decor',
    homeTextiles: 'Home textiles',
    exterior: 'Exterior',
    plants: 'Plants',
    kitchen: 'Kitchen'
  }
};
