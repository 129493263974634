import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ResultsKeyType {
  plural: LocalesKey;
  download: LocalesKey;
  see: LocalesKey;
}

export const resultsKeys = keyPathMirror<ResultsKeyType, string>(
  {
    plural: null,
    download: null,
    see: null
  },
  'models.results.'
);
