import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AttachmentsKeyType {
  plural: LocalesKey;
  pluralGenitive: LocalesKey;
  pluralLowerCase: LocalesKey;
  drawings: LocalesKey;
  gridView: LocalesKey;
  file: LocalesKey;
  listView: LocalesKey;
  references: LocalesKey;
  seeAll: LocalesKey;
  textures: LocalesKey;
  download: LocalesKey;
}

export const attachmentsKeys = keyPathMirror<AttachmentsKeyType, string>(
  {
    plural: null,
    pluralGenitive: null,
    pluralLowerCase: null,
    drawings: null,
    gridView: null,
    file: null,
    listView: null,
    references: null,
    seeAll: null,
    textures: null,
    download: null
  },
  'models.attachments.'
);
