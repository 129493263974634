import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

interface StringsType {
  applyToAll: LocalesKey;
  areYouSureThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeleteThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToCancelTheTask: LocalesKey;
  areYouSureYouWantToDeletTheBillingAddress: LocalesKey;
  byRatingTasksYouAcceptTaskResultsForTheClient: LocalesKey;
  changeVisibility: LocalesKey;
  dragAndDropFilesOr: LocalesKey;
  dropTheFilesHere: LocalesKey;
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly: LocalesKey;
  pleaseProvideYourAddress: LocalesKey;
  pleaseRateTheTask: LocalesKey;
  iConfirmThatIHaveReadAndAgreeTo: LocalesKey;
  acceptTheTaskResult: LocalesKey;
  weAreGladYouAreHappyWithResult: LocalesKey;
  onceYouAcceptTheTask: LocalesKey;
  ifYouWantTheResultTweakedOf: LocalesKey;
  youCanAlsoDownloadTheResult: {
    youCan: LocalesKey;
    download: LocalesKey;
    forChecking: LocalesKey;
  };
  weAreOnline: LocalesKey;
  weAreReadyToHelp: LocalesKey;
  writeHereWarning: LocalesKey;
  thePaymentHasBeenAcceptedForProcessingUsually: LocalesKey;
  thePaymentHasBeenCanceled: LocalesKey;
  thisCommentWouldBeVisibleOnlyInTimeline: LocalesKey;
  thisActionWillAffect: LocalesKey;
  youWillBeRedirectedToTheProjectPageIn: LocalesKey;
  youWillBeRedirectedToThePaymentsPageIn: LocalesKey;
  startTypingToSearchForClients: LocalesKey;
  errorLoadingImage: LocalesKey;
  updateCost: LocalesKey;
  updateDueDate: LocalesKey;
}

export const stringsKeys = keyPathMirror<StringsType, string>(
  {
    applyToAll: null,
    areYouSureThisActionCannotBeUndone: null,
    areYouSureYouWantToDeleteThisActionCannotBeUndone: null,
    areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: null,
    areYouSureYouWantToCancelTheTask: null,
    areYouSureYouWantToDeletTheBillingAddress: null,
    byRatingTasksYouAcceptTaskResultsForTheClient: null,
    changeVisibility: null,
    dragAndDropFilesOr: null,
    dropTheFilesHere: null,
    pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
      null,
    pleaseProvideYourAddress: null,
    pleaseRateTheTask: null,
    iConfirmThatIHaveReadAndAgreeTo: null,
    acceptTheTaskResult: null,
    weAreGladYouAreHappyWithResult: null,
    onceYouAcceptTheTask: null,
    ifYouWantTheResultTweakedOf: null,
    youCanAlsoDownloadTheResult: {
      youCan: null,
      download: null,
      forChecking: null
    },
    weAreOnline: null,
    weAreReadyToHelp: null,
    writeHereWarning: null,
    thePaymentHasBeenAcceptedForProcessingUsually: null,
    thePaymentHasBeenCanceled: null,
    thisCommentWouldBeVisibleOnlyInTimeline: null,
    thisActionWillAffect: null,
    youWillBeRedirectedToTheProjectPageIn: null,
    youWillBeRedirectedToThePaymentsPageIn: null,
    startTypingToSearchForClients: null,
    errorLoadingImage: null,
    updateCost: null,
    updateDueDate: null
  },
  'strings.'
);
