import React from 'react';
import Link from 'next/link';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../../helpers/Translate';

type NextPureLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

interface NextPureLinkHelperProps {
  className?: string;
  href: string;
  i18nText?: string;
  icon?: IconsEnum;
  iconClassName?: string;
  text?: string;
  prefetch?: boolean;
  onClick?: NextPureLinkHelperOnClick;
  onMouseEnter?: () => void;
}

function NextPureLinkHelper({
  className,
  href,
  i18nText,
  icon,
  iconClassName,
  text,
  prefetch,
  onClick,
  onMouseEnter
}: NextPureLinkHelperProps) {
  return (
    <Link href={href} prefetch={prefetch}>
      <a className={className} onClick={onClick} onMouseEnter={onMouseEnter}>
        {icon ? <Icon className={iconClassName} icon={icon} /> : null}
        {i18nText ? <Translate id={i18nText} /> : text}
      </a>
    </Link>
  );
}

export default NextPureLinkHelper;
