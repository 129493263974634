import { gql } from 'graphql-request';

import {
  PageID,
  PageUUID,
  PageNanoID,
  PageLocalizedBody,
  PageLocalizedName
} from '../pagesTypes';

export interface fetchPageShowPageQueryResponse {
  id: PageID;
  uuid: PageUUID;
  nanoId: PageNanoID;
  localizedName?: PageLocalizedName;
  localizedBody?: PageLocalizedBody;
}

export const FETCH_PAGE_SHOW_PAGE_QUERY = gql`
  query Page($uuid: ID!) {
    page(uuid: $uuid) {
      id
      uuid
      nanoId
      localizedName
      localizedBody
    }
  }
`;
