export default {
  createAnAccount: 'Create an account',
  createNewAccount: 'create new account',
  createArtistAccount: 'Create artist account',
  createWorkerAccount: 'Create worker account',
  forgotYourPasswordQuestion: 'Forgot your password?',
  signIn: 'Sign in',
  signUp: 'Sign up',
  signInToYourAccount: 'sign in to your account'
};
