export default {
  all: 'Все сообщения',
  areYouSureYouWantToSendThisMessageToAllProjectTasks:
    'Вы уверены, что хотите отправить сообщение во все задачи проекта?',
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible:
    'Вы уверены, что хотите пометить все сообщения как прочитанные? Это действие необратимо.',
  markAsRead: 'Отметить как прочитанное',
  markAsUnread: 'Отметить как непрочитанное',
  markAllAsRead: 'Отметить все как прочитенное',
  my: 'Мои сообщения',
  noPlural: 'Нет сообщений',
  showOlder: 'Показать старые сообщения'
};
