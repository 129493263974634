import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface FormsFieldsKeyType {
  account: LocalesKey;
  actionPlural: LocalesKey;
  action: LocalesKey;
  additionalEmail1: LocalesKey;
  additionalEmail2: LocalesKey;
  additionalInformation: LocalesKey;
  agreedHourlyRate: LocalesKey;
  amount: LocalesKey;
  android: LocalesKey;
  author: LocalesKey;
  businessEmail: LocalesKey;
  category: LocalesKey;
  cells: LocalesKey;
  city: LocalesKey;
  clientPrepayment: LocalesKey;
  clientStatus: LocalesKey;
  clientManagers: LocalesKey;
  coldSource: LocalesKey;
  colorTag: LocalesKey;
  commission: LocalesKey;
  company: LocalesKey;
  companySize: LocalesKey;
  country: LocalesKey;
  creditCommission: LocalesKey;
  hostingCommission: LocalesKey;
  team: LocalesKey;
  balance: LocalesKey;
  balanceType: LocalesKey;
  blocked: LocalesKey;
  body: LocalesKey;
  burning: LocalesKey;
  created: LocalesKey;
  createdAt: LocalesKey;
  currency: LocalesKey;
  danger: LocalesKey;
  defaultBillingAddress: LocalesKey;
  defaultPayoneerAccount: LocalesKey;
  description: LocalesKey;
  deferredPaymentDays: LocalesKey;
  discount: LocalesKey;
  dpi: LocalesKey;
  email: LocalesKey;
  emailAddress: LocalesKey;
  estimateCost: LocalesKey;
  expectedPaymentDate: LocalesKey;
  expectedPaymentAmount: LocalesKey;
  experience: LocalesKey;
  feedback: LocalesKey;
  fileExtension: LocalesKey;
  fileFormat: LocalesKey;
  filterName: LocalesKey;
  filterParts: LocalesKey;
  financeRole: LocalesKey;
  financeTags: LocalesKey;
  firstPaymentDate: LocalesKey;
  frontend: LocalesKey;
  fullName: LocalesKey;
  groups: LocalesKey;
  height: LocalesKey;
  hours: LocalesKey;
  id: LocalesKey;
  ios: LocalesKey;
  indexedAt: LocalesKey;
  implementationDate: LocalesKey;
  implementedAt: LocalesKey;
  integration: LocalesKey;
  isMentor: LocalesKey;
  kind: LocalesKey;
  rateEducationPoint: LocalesKey;
  lastActivityTime: LocalesKey;
  lastActivity: LocalesKey;
  lastPaymentDate: LocalesKey;
  manualClientStatus: LocalesKey;
  marketingSource: LocalesKey;
  maxPrice: LocalesKey;
  mentor: LocalesKey;
  message: LocalesKey;
  bodyEn: LocalesKey;
  bodyUk: LocalesKey;
  minPrice: LocalesKey;
  minutes: LocalesKey;
  myProjects: LocalesKey;
  myTasks: LocalesKey;
  name: LocalesKey;
  nanoId: LocalesKey;
  nameEn: LocalesKey;
  nameUk: LocalesKey;
  surname: LocalesKey;
  surnameEn: LocalesKey;
  surnameUk: LocalesKey;
  nda: LocalesKey;
  noGo: LocalesKey;
  paymentType: LocalesKey;
  payoneerId: LocalesKey;
  payoneerIdPe: LocalesKey;
  password: LocalesKey;
  phone: LocalesKey;
  position: LocalesKey;
  price: LocalesKey;
  preferableCurrency: LocalesKey;
  prepayment: LocalesKey;
  proficiencyLevel: LocalesKey;
  projectManager: LocalesKey;
  projectManagers: LocalesKey;
  providedFullName: LocalesKey;
  providedFullNameEn: LocalesKey;
  providedFullNameUk: LocalesKey;
  qualifications: LocalesKey;
  rankedName: LocalesKey;
  realDeclineReasons: LocalesKey;
  referer: LocalesKey;
  registrationDate: LocalesKey;
  role: LocalesKey;
  roles: LocalesKey;
  totalBalance: LocalesKey;
  status: LocalesKey;
  source: LocalesKey;
  sourceFilesCount: LocalesKey;
  specializations: LocalesKey;
  state: LocalesKey;
  started: LocalesKey;
  startDate: LocalesKey;
  street: LocalesKey;
  subCategories: LocalesKey;
  subject: LocalesKey;
  tasksPrice: LocalesKey;
  taskTeam: LocalesKey;
  title: LocalesKey;
  type: LocalesKey;
  updatedAt: LocalesKey;
  uuid: LocalesKey;
  user: LocalesKey;
  webSite: LocalesKey;
  width: LocalesKey;
  wig: LocalesKey;
  zipCode: LocalesKey;
}

export const formsFields = keyPathMirror<FormsFieldsKeyType, string>(
  {
    account: null,
    actionPlural: null,
    action: null,
    additionalEmail1: null,
    additionalEmail2: null,
    additionalInformation: null,
    agreedHourlyRate: null,
    amount: null,
    android: null,
    author: null,
    businessEmail: null,
    category: null,
    cells: null,
    city: null,
    clientPrepayment: null,
    clientStatus: null,
    clientManagers: null,
    coldSource: null,
    colorTag: null,
    commission: null,
    company: null,
    companySize: null,
    country: null,
    creditCommission: null,
    hostingCommission: null,
    team: null,
    balance: null,
    balanceType: null,
    blocked: null,
    body: null,
    burning: null,
    created: null,
    createdAt: null,
    currency: null,
    danger: null,
    defaultBillingAddress: null,
    defaultPayoneerAccount: null,
    description: null,
    deferredPaymentDays: null,
    discount: null,
    dpi: null,
    email: null,
    emailAddress: null,
    estimateCost: null,
    expectedPaymentDate: null,
    expectedPaymentAmount: null,
    experience: null,
    feedback: null,
    fileExtension: null,
    fileFormat: null,
    filterName: null,
    filterParts: null,
    financeRole: null,
    financeTags: null,
    firstPaymentDate: null,
    frontend: null,
    fullName: null,
    groups: null,
    height: null,
    hours: null,
    id: null,
    ios: null,
    indexedAt: null,
    implementationDate: null,
    implementedAt: null,
    integration: null,
    isMentor: null,
    kind: null,
    rateEducationPoint: null,
    lastActivityTime: null,
    lastActivity: null,
    lastPaymentDate: null,
    manualClientStatus: null,
    marketingSource: null,
    maxPrice: null,
    mentor: null,
    message: null,
    bodyEn: null,
    bodyUk: null,
    minPrice: null,
    minutes: null,
    myProjects: null,
    myTasks: null,
    name: null,
    nanoId: null,
    nameEn: null,
    nameUk: null,
    surname: null,
    surnameEn: null,
    surnameUk: null,
    nda: null,
    noGo: null,
    paymentType: null,
    payoneerId: null,
    payoneerIdPe: null,
    password: null,
    phone: null,
    position: null,
    price: null,
    preferableCurrency: null,
    prepayment: null,
    proficiencyLevel: null,
    projectManager: null,
    projectManagers: null,
    providedFullName: null,
    providedFullNameEn: null,
    providedFullNameUk: null,
    qualifications: null,
    rankedName: null,
    realDeclineReasons: null,
    referer: null,
    registrationDate: null,
    role: null,
    roles: null,
    totalBalance: null,
    status: null,
    source: null,
    sourceFilesCount: null,
    specializations: null,
    state: null,
    started: null,
    startDate: null,
    street: null,
    subCategories: null,
    subject: null,
    tasksPrice: null,
    taskTeam: null,
    title: null,
    type: null,
    updatedAt: null,
    uuid: null,
    user: null,
    webSite: null,
    width: null,
    wig: null,
    zipCode: null
  },
  'forms.fields.'
);
