import { memo, useCallback, useEffect } from 'react';

function isClickOutside(
  target: EventTarget | null,
  element: HTMLElement | null
): boolean {
  if (!target || !element) {
    return true;
  }
  if (target === element) {
    return false;
  }
  if (target instanceof Node && element.contains(target)) {
    return false;
  }
  return true;
}

interface PopoverCloseOnOuterClickProps {
  popoverElement: HTMLElement | null;
  referenceElement: HTMLElement | null;
  closePopover: () => void;
}

const PopoverCloseOnOuterClick = ({
  popoverElement,
  referenceElement,
  closePopover
}: PopoverCloseOnOuterClickProps) => {
  const handlePress = useCallback(
    (e: MouseEvent) => {
      if (
        isClickOutside(e.target, popoverElement) &&
        isClickOutside(e.target, referenceElement)
      ) {
        closePopover();
      }
    },
    [popoverElement, referenceElement, closePopover]
  );

  useEffect(() => {
    window.addEventListener('click', handlePress);
    return () => {
      window.removeEventListener('click', handlePress);
    };
  }, [handlePress]);

  return null;
};

export default memo(PopoverCloseOnOuterClick);
