import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TaskMemberInvitesKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  accept: LocalesKey;
  acceptAll: LocalesKey;
  acceptSelected: LocalesKey;
  decline: LocalesKey;
  declineAll: LocalesKey;
  declineSelected: LocalesKey;
  search: LocalesKey;
  areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone: LocalesKey;
  areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone: LocalesKey;
}

export const taskMemberInvitesKeys = keyPathMirror<
  TaskMemberInvitesKeyType,
  string
>(
  {
    list: null,
    plural: null,
    accept: null,
    acceptAll: null,
    acceptSelected: null,
    decline: null,
    declineAll: null,
    declineSelected: null,
    search: null,
    areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone: null,
    areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone: null,
    areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone: null
  },
  'models.taskMemberInvites.'
);
