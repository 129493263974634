export type BaseBrand<K, T extends string> = K & { readonly __baseBrand: T };
export type ModelBrand<K, T extends string> = K & { readonly __modelBrand: T };

export type IP = string;
export type ID = string;
export type UUID = BaseBrand<string, 'UUID'>;
export type NanoID = BaseBrand<string, 'NanoID'>;
export type DateType = string;
export type MoneyType = number;
export type CreatedAt = DateType;
export type UpdatedAt = DateType;
export type DeletedAt = DateType | null;
export type IndexedAt = DateType | null;

export type IsLoading = boolean;
export type IsDisabled = boolean;
export type IsFetched = boolean;
export type IsFetchingNextPage = boolean;
export type IsOptimisticUpdate = boolean;
export type ErrorMessage = string | undefined;
export type ErrorMessages = ErrorMessage[];
export type SuccessMessage = string | null;
export type IsPlaceholderData = boolean;
export type HasNextPage = boolean;
export type IsSelected = boolean;

export type ClassName = string;
export type I18nText = string;

export type FormID = string;

export type FileUrl = string;
export type PathUrl = string;
export type FileName = string;
export type FileSize = BaseBrand<number, 'FileSize'>;
export type DeviceNanoId = BaseBrand<string, 'DeviceNanoId'>;

export type BreadcrumbsUrl = {
  i18nText: I18nText;
  pathUrl: PathUrl;
};
export type BreadcrumbsUrls = BreadcrumbsUrl[];

export type HexColor = BaseBrand<string, 'HexColor'>;

export type Locale = 'en' | 'ru' | 'uk';

export const enum Currencies {
  USD = 'USD',
  RUB = 'RUB',
  UAH = 'UAH',
  EUR = 'EUR'
}

export const enum PayoneerAccountTypes {
  PAYONEER_ID = 'payoneer_id',
  PAYONEER_ID_PE = 'payoneer_id_pe'
}

export const enum FieldIcons {
  DOLLAR = '$'
}
