import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SourceFilesKeyType {
  plural: LocalesKey;
  upload: LocalesKey;
}

export const sourceFilesKeys = keyPathMirror<SourceFilesKeyType, string>(
  {
    plural: null,
    upload: null
  },
  'models.sourceFiles.'
);
