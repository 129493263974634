export default {
  accordingTo: 'according to',
  account: 'Account',
  activity: 'Activity',
  add: 'Add',
  addItem: 'Add item',
  addReaction: 'Add reaction',
  addToFavorites: 'Add to favorites',
  addUser: 'Add user',
  addYourCommentOptional: 'Add your comment (optional)',
  allActivity: 'All activity',
  already_selected: 'Already selected',
  amountDue: 'Amount due',
  and: 'and',
  any: 'Any',
  anyType: 'Any type',
  applicationLanguage: 'Application language',
  artists: 'Artists',
  assignAsCreator: 'Assign as creator',
  attach: 'Attach',
  attachImage: 'Attach image',
  backToProject: 'Back to project',
  backtrace: 'Backtrace',
  badDebt: 'Bad debt',
  blocked: 'Blocked',
  brief: 'Brief',
  browse: 'Browse',
  budget: 'Budget',
  cancel: 'Cancel',
  caution: 'Caution!',
  clear: 'Clear',
  clearFilters: 'Clear filters',
  clearSelection: 'Clear selection',
  clickToCopy: 'Click to copy',
  client: 'Client',
  clientDebt: 'Client debt',
  clients: 'Clients',
  close: 'Close',
  colors: 'Colors',
  confirm: 'Confirm',
  contactSupport: 'Contact support',
  copyId: 'Copy ID',
  copyLink: 'Copy link',
  copyUuid: 'Copy UUID',
  create: 'Create',
  criticalRequirements: 'Critical requirements',
  date: 'Date',
  dateAndTimeFormat: 'Date and time format',
  dateOfBirth: 'Date of birth',
  debt: 'Debt',
  delete: 'Delete',
  delete_filter: 'Delete filter',
  description: 'Description',
  descriptionOptional: 'Description (optional)',
  download: 'Download',
  downloadAllUpperCase: 'DOWNLOAD ALL',
  dpi: 'dpi',
  draftBudget: 'Draft budget',
  drawings: 'Drawings',
  dueDate: 'Due date',
  duplicate: 'Duplicate',
  edit: 'Edit',
  emailAddress: 'Email address',
  english: 'English',
  errorMessage: 'Error message',
  eur: 'EUR',
  expand_view: 'Expand view',
  expenses: 'Expenses',
  favorite: 'Favorite',
  files: 'Files',
  filter: 'Filter',
  filterFavorite: 'Filter favorite',
  filters: 'Filters',
  flagged: 'Flagged',
  forward: 'Forward',
  from: 'From',
  general: 'General',
  generate: 'Generate',
  helpCenter: 'Help Center',
  hidden: 'Hidden',
  hide: 'Hide',
  hideList: 'Hide list',
  homepage: 'Go to the home page',
  in: 'in',
  include: 'Include',
  info: 'Info',
  invisibleForClient: 'Invisible for client',
  invisibleForWorker: 'Invisible for worker',
  invite_user: 'Invite user',
  joined: 'Joined',
  languagesSpoken: 'Languages spoken',
  leave: 'Leave',
  leaveMessage: 'Leave Message',
  location: 'Location',
  log_out: 'Log out',
  login_page: 'Go to the login page',
  media: 'Media',
  members: 'Members',
  membersGenitiveLowerCase: 'members',
  model: 'Model',
  more: 'More',
  myLibrary: 'My library',
  name: 'Name',
  nameEn: 'Name (En)',
  nda: 'NDA',
  needHelp: 'Need help?',
  new: 'New',
  no: 'No',
  noResultsYet: 'No results yet',
  noSearchResults: 'No search results',
  of: 'of',
  ok: 'Ok',
  openOptions: 'Open options',
  open_in_new_tab: 'Open in new tab',
  or: 'Or',
  paid: 'Paid',
  pay: 'Pay',
  payer: 'Payer',
  paymentMethod: 'Payment Method',
  payments: 'Payments',
  pendingPayments: 'Pending payments',
  phoneNumber: 'Phone number',
  pinned: 'Pinned',
  policies: 'Policies',
  postMessage: 'Post message',
  price: 'Price',
  privacy: 'Privacy',
  privacyPolicy: 'Privacy policy',
  processing: 'Processing...',
  progress: 'Progress',
  px: 'px',
  receiveNotifications: 'Receive notifications',
  recently_selected: 'Recently selected',
  references: 'References',
  refund: 'Refund',
  remove: 'Remove',
  removeFromFavorites: 'Remove from favorites',
  reset_password: 'Reset password',
  results: 'results',
  rub: 'RUB',
  russian: 'Russian',
  save: 'Save',
  saveFilter: 'Save filter',
  savedFilters: 'Saved filters',
  scopeOfWork: 'Scope of work',
  search: 'Search',
  searchResults: 'Search results',
  search_user_by_name: 'Search user by name',
  searching: 'Searching...',
  secondsGenitive: 'seconds',
  select: 'Select',
  selectToAddToTheProject: 'Select to add to the project',
  selected: 'Selected',
  send: 'Send',
  sendInstructions: 'Send instructions',
  sendToAllTasks: 'Send to all tasks',
  sendViaEmail: 'Send via email',
  serviceAgreement: 'Service agreement',
  setDueDate: 'Set due date',
  settings: 'Settings',
  show: 'Show',
  showAll: 'Show all',
  showLess: 'Show less',
  showList: 'Show list',
  showMore: 'Show more',
  showing: 'Showing',
  sign_out: 'Sign out',
  sketches: 'Sketches',
  sku: 'SKU',
  startDate: 'Start date',
  state: 'State',
  status: 'Status',
  submit: 'Submit',
  surnameEn: 'Surname (En)',
  system: 'System',
  textures: 'Textures',
  threeDStock: '3D Stock',
  threeDs: '3Ds',
  time: 'Time',
  timeZone: 'Time zone',
  to: 'To',
  total: 'Total',
  totalDebt: 'Total debt',
  tryAgain: 'Try again',
  uah: 'UAH',
  ukraine: 'Ukrainian',
  underpayment: 'Underpayment',
  unselectAllItems: 'Unselect all items',
  update: 'Update',
  updateImageVersions: 'Update image versions',
  updated: 'Updated',
  usd: 'USD',
  user: 'User',
  view: 'View',
  viewAll: 'View all',
  viewAllMembers: 'View all members',
  viewCR: 'View CR',
  viewProfile: 'View Profile',
  visibility: 'Visibility',
  visibilityForClient: 'Visibility for client',
  visibilityForWorker: 'Visibility for worker',
  visible: 'Visible',
  visibleForClient: 'Visible for client',
  workers: 'Workers',
  workflowGuide: 'Workflow guide',
  writeHere: 'Write here',
  yes: 'Yes',
  colorSchemeDark: 'Dark',
  colorSchemeLight: 'Light',
  colorScheme: 'Color scheme',
  show_n_selected_items: {
    show: 'Show',
    hide: 'Hide',
    selected_items: 'selected items'
  },
  timer: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
  },
  letters: {
    h: 'h',
    m: 'm'
  }
};
