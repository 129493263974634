import React, { memo, useCallback } from 'react';

import { UserMenuTypes } from './UserMenu.types';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useMenu } from '../../hooks/useMenu';

import { UserMenuAvatar } from './components/UserMenuAvatar';
import { UserMenuLogoutButton } from './components/UserMenuLogoutButton';
import { UserMenuProfileLink } from './components/UserMenuProfileLink';
import { UserMenuSettingsLink } from './components/UserMenuSettingsLink';
import { UserMenuProfileInfo } from './components/UserMenuProfileInfo';
import { UserMenuProfilePaymentsLink } from './components/UserMenuProfilePaymentsLink';

import { CheckPermissions } from '../../../helpers/CheckPermissions';
import { DropdownDivider } from '../../../helpers/DropdownDivider';
import { PopoverWithPortal, usePopoverToggle } from '../../../helpers/Popover';
import { PopoverPlacement } from '../../../helpers/Popover/popoverConstants';
import { RefButtonHelper } from '../../../helpers/buttons/RefButtonHelper';

import { UsersPermissions } from '../../../main/users/usersConstants';

interface UserMenuProps {
  type: UserMenuTypes;
  onLogoutUser: () => void;
  userNanoId: UserNanoID;
}

function UserMenu({ type, userNanoId, onLogoutUser }: UserMenuProps) {
  const { closeSecondaryMenu } = useMenu();

  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverToggle();

  const closeMenus = useCallback(() => {
    closeSecondaryMenu();
    closePopover();
  }, [closeSecondaryMenu, closePopover]);

  return (
    <>
      <RefButtonHelper
        className="flex mx-auto relative rounded-full text-sm text-white"
        ref={referencePopoverCallbackRef}
        onClick={togglePopover}
      >
        <span className="sr-only">Open user menu</span>
        <UserMenuAvatar type={type} />
      </RefButtonHelper>
      {isPopoverOpen ? (
        <PopoverWithPortal
          referenceElement={referencePopoverElement}
          placement={
            type === UserMenuTypes.MOBILE
              ? PopoverPlacement.TOP_END
              : PopoverPlacement.RIGHT_END
          }
          closePopover={closePopover}
          distanceOffset={6}
          className="min-w-48 z-30 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
        >
          <CheckPermissions action={UsersPermissions.READ_USER_MENU_USER_INFO}>
            <UserMenuProfileInfo userNanoId={userNanoId} />
            <DropdownDivider />
          </CheckPermissions>
          <UserMenuProfileLink closeMenus={closeMenus} />
          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_PROFILE_PAYMENTS_LINK}
          >
            <UserMenuProfilePaymentsLink closeMenus={closeMenus} />
          </CheckPermissions>
          <UserMenuSettingsLink closeMenus={closeMenus} />
          <DropdownDivider />
          <UserMenuLogoutButton
            closeMenus={closeMenus}
            onLogout={onLogoutUser}
          />
        </PopoverWithPortal>
      ) : null}
    </>
  );
}

export default memo<UserMenuProps>(UserMenu);
