export default {
  activate: 'Activate user',
  activateUser: 'Activate user',
  activateWarning: 'Are you sure you want to activate selected users?',
  add: 'Add user',
  addCellsToUsers: 'Add cells to users',
  all: 'All people',
  balance: 'Balance',
  block: 'Block user',
  blockedSingular: 'Blocked user',
  blockedPlural: 'Blocked users',
  blockUser: 'Block user',
  blockWarning: 'Are you sure you want to block selected users?',
  create: 'Create user',
  clientsPlural: 'Clients',
  cm: 'CM',
  delete_account: 'Delete account',
  delete: 'Delete user',
  debt: 'Debt',
  edit: 'Edit user',
  invite: 'Invite user',
  plural: 'Users',
  pluralLowerCase: 'users',
  workersPlural: 'Workers',
  pluralDowncase: 'users',
  search: 'Search users',
  select: 'Select user...',
  selected: 'Selected users',
  singular: 'User',
  sigmaPayments: 'Σ Payments',
  unblock: 'Unblock user',
  update: 'Update user',
  createdSuccess: 'User successfully created.',
  deletedSuccess: 'User successfully deleted.',
  info: 'User Info',
  count: 'Users {count}',
  updatedSuccess: 'User successfully updated.',
  name: 'Name',
  phone: 'Phone',
  pm: 'PM',
  email: 'Email',
  financeRole: 'Finance role',
  experience: 'Experience',
  revenue: 'Revenue',
  kAvr: 'K AVR',
  kEdp: 'K EDP',
  lastPayments: 'Last Payments',
  lastActivity: 'Last Activity',
  cell: 'Cell',
  roles: 'Roles',
  createdAt: 'Created at',
  pendingPayments: {
    plural: 'Pending payments',
    singular: 'Pending payment',
    types: {
      badDebt: 'Bad debt',
      debt: 'Debt',
      underpayment: 'Underpayment'
    },
    no: 'No pending payments'
  },
  status: 'Status',
  statuses: {
    new: 'New',
    active: 'Active',
    paused: 'Paused',
    declined: 'Declined',
    realDecline: 'Real decline',
    noPayments: 'No payments'
  }
};
