import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProjectMemberInvitesKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  accept: LocalesKey;
  acceptAll: LocalesKey;
  acceptSelected: LocalesKey;
  decline: LocalesKey;
  declineAll: LocalesKey;
  declineSelected: LocalesKey;
  search: LocalesKey;
  areTouSureYouWantToAcceptAllProjectInvitationsThisActionCannotBeUndone: LocalesKey;
  areTouSureYouWantToDeclineAllProjectInvitationsThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeclineProjectInvitationThisActionCannotBeUndone: LocalesKey;
}

export const projectMemberInvitesKeys = keyPathMirror<
  ProjectMemberInvitesKeyType,
  string
>(
  {
    list: null,
    plural: null,
    accept: null,
    acceptAll: null,
    acceptSelected: null,
    decline: null,
    declineAll: null,
    declineSelected: null,
    search: null,
    areTouSureYouWantToAcceptAllProjectInvitationsThisActionCannotBeUndone:
      null,
    areYouSureYouWantToDeclineProjectInvitationThisActionCannotBeUndone: null,
    areTouSureYouWantToDeclineAllProjectInvitationsThisActionCannotBeUndone:
      null
  },
  'models.projectMemberInvites.'
);
