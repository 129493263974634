import compact from 'lodash/compact';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import { FetchItemGqlQuery, NanoID, UUID } from '../../../../../../../types';

import { graphQLClient } from '../../graphQLClients';

import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
}

export function fetchItem({ query, uuid }: FetchItemProps) {
  productionConsole(
    'log',
    'fetchItem',
    uuid,
    last(uniq(compact(query.match(/(query\s.+)\(/))))
  );

  return graphQLClient.request(query, { uuid });
}
