import get from 'lodash/get';
import fromPairs from 'lodash/fromPairs';

import {
  UserFullName,
  UserID,
  UserImageFile,
  UserLocale,
  UserNanoID,
  UserPermissionsAction,
  UserUUID,
  UserClient
} from '../../../main/users/usersTypes';

import { productionConsole } from '../../../utils/productionConsole';

import { UserInfo } from './useAuth.types';

class AuthenticatedUser {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  locale: UserLocale;
  image: { file: UserImageFile } | null;
  client: UserClient;
  permissions: { [key: string]: boolean };
  shownPermissions: { [key: string]: UserPermissionsAction };

  constructor(values: UserInfo) {
    this.id = values.id;
    this.uuid = values.uuid;
    this.nanoId = values.nanoId;
    this.locale = values.locale;
    this.fullName = values.fullName;
    this.image = values.image;
    this.client = values.client;
    this.permissions = fromPairs<boolean>(
      values.permissions.map((permission) => [permission.action, true])
    );
    this.shownPermissions = {};
  }

  get(field: string, defaultValue = null) {
    return get(this, field, defaultValue);
  }

  hasPermissions(action: UserPermissionsAction): boolean {
    const actionResult = this.permissions[action] || false;

    if (!actionResult && !this.shownPermissions[action]) {
      this.shownPermissions[action] = action;
      productionConsole('log', 'hasPermissions', this.get('id'), action, {
        actionResult,
        client: this.get('client'),
        user: this
      });
    }

    // if (!this.shownPermissions[action]) {
    //   this.shownPermissions[action] = action;
    //   productionConsole('warn', 'hasPermissions', this.get('id'), action, {
    //     actionResult,
    //     client: this.get('client'),
    //     user: this
    //   });
    // }

    return actionResult;
  }

  // hasPermissions(
  //   action: UserPermissionsAction,
  //   ignoreSelfAction: UserPermissionsAction | null = null,
  //   userId: UserID | null = null
  // ): boolean {
  //   return true;
  //
  //   // const actionResult = has(this.permissions || {}, action) || false;
  //   //
  //   // if (ignoreSelfAction && userId) {
  //   //   return this.get('id') === userId
  //   //     ? actionResult
  //   //     : has(this.permissions || {}, ignoreSelfAction) || false;
  //   // }
  //   //
  //   // if (!actionResult && !this.shownPermissions[action]) {
  //   //   this.shownPermissions[action] = action;
  //   //   // productionConsole('log', 'hasPermissions', this.get('id'), action, {
  //   //   //   actionResult,
  //   //   //   client: this.get('client'),
  //   //   //   ignoreSelfAction,
  //   //   //   userId,
  //   //   //   user: this
  //   //   // });
  //   // }
  //   //
  //   // if (!this.shownPermissions[action]) {
  //   //   this.shownPermissions[action] = action;
  //   //   // productionConsole('warn', 'hasPermissions', this.get('id'), action, {
  //   //   //   actionResult,
  //   //   //   client: this.get('client'),
  //   //   //   ignoreSelfAction,
  //   //   //   userId,
  //   //   //   user: this
  //   //   // });
  //   // }
  //   //
  //   // return actionResult;
  // }
  //
  // isClient() {
  //   return this.get('client');
  // }

  isEn() {
    return this.get('locale') === 'en';
  }

  isRu() {
    return this.get('locale') === 'ru';
  }

  isUk() {
    return this.get('locale') === 'ru';
  }

  isAuthenticated() {
    return !!this.get('uuid');
  }

  // canChange(user: CurrentUser, action: string) {
  //   if (this.get('id') === user.id) {
  //     return true;
  //   }
  //
  //   return this.hasPermissions(action);
  // }
}

export default AuthenticatedUser;
