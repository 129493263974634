import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TransactionsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  noPlural: LocalesKey;
  balanceTypes: {
    debitAccount: LocalesKey;
    creditAccount: LocalesKey;
    projectAccount: LocalesKey;
    taskAccount: LocalesKey;
    amount: LocalesKey;
    prepaidAmount: LocalesKey;
    bonusAmount: LocalesKey;
  };
  totalBalance: LocalesKey;
}

export const transactionsKeys = keyPathMirror<TransactionsKeyType, string>(
  {
    list: null,
    plural: null,
    create: null,
    delete: null,
    noPlural: null,
    balanceTypes: {
      debitAccount: null,
      creditAccount: null,
      projectAccount: null,
      taskAccount: null,
      amount: null,
      prepaidAmount: null,
      bonusAmount: null
    },
    totalBalance: null
  },
  'models.transactions.'
);
