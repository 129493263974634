import accounts from './accounts';
import attachments from './attachments';
import bills from './bills';
import categories from './categories';
import colors from './colors';
import downloads from './downloads';
import evaluations from './evaluations';
import evaluationSets from './evaluationSets';
import files from './files';
import financeAddresses from './financeAddresses';
import images from './images';
import invoices from './invoices';
import lifestyles from './lifestyles';
import materials from './materials';
import menu from './menu';
import messages from './messages';
import operations from './operations';
import pages from './pages';
import payments from './payments';
import permissions from './permissions';
import products from './products';
import profiles from './profiles';
import projects from './projects';
import projectMembers from './projectMembers';
import projectMemberInvites from './projectMemberInvites';
import projectNotifications from './projectNotifications';
import reports from './reports';
import results from './results';
import settings from './settings';
import selectedLifestyles from './selectedLifestyles';
import selectedProducts from './selectedProducts';
import sourceFiles from './sourceFiles';
import statuses from './statuses';
import tags from './tags';
import tasks from './tasks';
import taskMembers from './taskMembers';
import taskMemberInvites from './taskMemberInvites';
import taskNotifications from './taskNotifications';
import transactionTags from './transactionTags';
import teams from './teams';
import todoItems from './todoItems';
import transactions from './transactions';
import users from './users';

export default {
  accounts,
  attachments,
  bills,
  categories,
  colors,
  downloads,
  evaluations,
  evaluationSets,
  files,
  financeAddresses,
  images,
  invoices,
  lifestyles,
  materials,
  menu,
  messages,
  operations,
  pages,
  payments,
  permissions,
  products,
  profiles,
  projects,
  projectMembers,
  projectMemberInvites,
  projectNotifications,
  reports,
  results,
  settings,
  selectedLifestyles,
  selectedProducts,
  sourceFiles,
  statuses,
  tags,
  tasks,
  taskMembers,
  taskMemberInvites,
  taskNotifications,
  teams,
  todoItems,
  transactions,
  transactionTags,
  users
};
