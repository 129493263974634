import { useTranslation } from 'react-i18next';

import { I18nText } from '../../../types';

type TranslateFunction = (i18nText: I18nText) => string;

interface UseTranslateReturnType {
  t: TranslateFunction;
}

function useTranslate(): UseTranslateReturnType {
  const { t } = useTranslation();

  return { t };
}

export default useTranslate;
