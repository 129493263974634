export default {
  hide: 'Сховати меню',
  keepOpen: 'Тримати меню відкритим',
  links: {
    access: 'Доступ',
    active: 'Активні',
    blocked: 'Заблоковані',
    dashboard: 'Dashboard',
    favorites: 'Обрані',
    invoices: 'Рахунки',
    main: 'Головна',
    permissions: 'Дозволи',
    people: 'Люди',
    pages: 'Сторінки',
    reports: 'Звіти',
    content: 'Контент',
    products: 'Предмети',
    profile: 'Профіль',
    projects: 'Проекты',
    tasks: 'Задачі',
    messages: 'Повідомлення'
  },
  tabs: {
    dashboard: 'Dashboard',
    notifications: 'Сповіщення',
    tasksAndProjects: 'Задачі та проекти',
    stock3d: '3D каталог',
    users: 'Користувачі',
    workers: 'Працівники',
    clients: 'Клієнти',
    admin: 'Панель адміністратора'
  }
};
