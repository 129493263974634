import React from 'react';
import cl from 'classnames';

import { ClassName, I18nText } from '../../../../../../types';

import { PageNanoID } from '../../../../../../main/pages/pagesTypes';
import { IconsEnum } from '../../../../../../assets/icons/types';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  fetchPageShowPageQueryResponse
} from '../../../../../../main/pages/queries/fetchPageShowPage.query';

import { usePageShowPage } from '../../../../../../main/pages/hooks/usePageShowPage';

import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { SimpleModalButton } from '../../../../../../helpers/buttons/SimpleModalButton';

import { words } from '../../../../../../locales/keys';
import { PageCache } from '../../../../../../main/pages/PageCache';
import { HELP_PAGE_NANOID } from '../../../../../../config';

interface HelpCenterModalButtonProps {
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
}

function HelpCenterModalButton({
  className,
  iconClassName,
  icon,
  i18nText
}: HelpCenterModalButtonProps) {
  const { page, pageLoading, togglePageData } =
    usePageShowPage<fetchPageShowPageQueryResponse>({
      uuid: HELP_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(HELP_PAGE_NANOID as PageNanoID)
    });

  return (
    <SimpleModalButton
      className={cl(
        className ||
          'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      )}
      iconClassName={iconClassName}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nCancelText={words.close}
      i18nText={i18nText}
      title={page?.localizedName}
      isLoading={pageLoading}
      onOpen={togglePageData}
      icon={icon}
      withoutSubmitButton
      tooltipI18nText={words.helpCenter}
      tooltipSingleton
    >
      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />
        </div>
      </LoadingSkeleton>
    </SimpleModalButton>
  );
}

export default HelpCenterModalButton;
