import React, { Fragment, memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { useMenu } from '../../../hooks/useMenu';

import { useAuth } from '../../../../auth/hooks/useAuth';
import { usePrimaryMenuHover } from './hooks/usePrimaryMenuHover';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';
import { Icon } from '../../../../helpers/Icon';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { PrimaryNav } from '../PrimaryNav';
import { UserMenu, UserMenuTypes } from '../../UserMenu';

import { PrimaryMenuKeepSecondaryButton } from './components/PrimaryMenuKeepSecondaryButton';
import { PrimaryMenuMobilesToggleSecondaryMenu } from './components/PrimaryMenuMobilesToggleSecondaryMenu';
import { ContactSupportModalButton } from './components/ContactSupportModalButton';

import { MainPath } from '../../../../main/MainPath';
import { HelpCenterModalButton } from './components/HelpCenterModalButton';

import { AppPermissions } from '../../../appConstants';

interface PrimaryMenuProps {
  onLogoutUser: () => void;
}

function PrimaryMenu({ onLogoutUser }: PrimaryMenuProps) {
  const { isAuthenticated, currentUser } = useAuth();

  const {
    isSecondaryMenuKeepOpen,
    openSecondaryMenu,
    toggleKeepSecondaryMenu
  } = useMenu();

  const { handleMouseEnter, handleMouseLeave } = usePrimaryMenuHover({
    openSecondaryMenu
  });

  return (
    <div
      data-id="nav-primary-wrap"
      className="z-20 flex sm:flex-col h-10 sm:h-full w-full sm:w-16 border-t sm:border-t-0 sm:border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isAuthenticated ? (
        <div className="hidden sm:inline-flex h-16 w-16 items-center justify-center text-white bg-gradient-to-tr from-blue-600 to-blue-400">
          {currentUser?.hasPermissions(
            AppPermissions.READ_MENU_DASHBOARD_LINK
          ) ? (
            <NextPureLinkHelper
              href={MainPath.index()}
              className="outline-none h-full w-full flex items-center justify-center"
              icon={IconsEnum.AV}
            />
          ) : (
            <Icon icon={IconsEnum.AV} />
          )}
        </div>
      ) : null}

      <nav
        data-id="nav-primary"
        className="flex-grow w-full md:flex-shrink-0 relative"
        aria-label="Sidebar"
      >
        <div className="absolute inset-0 flex flex-col md:overflow-y-auto md:overflow-x-hidden justify-between">
          <div className="flex sm:flex-col sm:py-3 sm:px-1 sm:space-y-1 h-full w-full sm:h-auto">
            <PrimaryMenuMobilesToggleSecondaryMenu />
            {isAuthenticated ? <PrimaryNav /> : null}
            <div className="p-1 sm:hidden">
              {isAuthenticated ? (
                <UserMenu
                  type={UserMenuTypes.MOBILE}
                  onLogoutUser={onLogoutUser}
                  userNanoId={currentUser.nanoId}
                />
              ) : null}
            </div>
          </div>
          <div
            data-id="nav-primary-middle"
            className="flex-shrink-0 p-3 hidden sm:block"
          >
            <PrimaryMenuKeepSecondaryButton
              isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
              toggleKeepSecondaryMenu={toggleKeepSecondaryMenu}
            />
          </div>
          <div
            data-id="nav-primary-bottom"
            className="flex-shrink-0 py-3 hidden sm:block space-y-3"
          >
            {isAuthenticated ? (
              <Fragment>
                <div className="px-3">
                  <TooltipSingletonSourceWrapper
                    placement={TooltipPlacement.TOP}
                    withArrow
                  >
                    <HelpCenterModalButton
                      icon={IconsEnum.QUESTION_MARK_CIRCLE_SOLID}
                      iconClassName="h-6 w-6 p-0.5"
                    />
                    <ContactSupportModalButton
                      isAuthenticated={isAuthenticated}
                      icon={IconsEnum.CONTACT_SUPPORT}
                      iconClassName="h-6 w-6 p-0.5"
                    />
                  </TooltipSingletonSourceWrapper>
                </div>
                <UserMenu
                  type={UserMenuTypes.DESKTOP}
                  onLogoutUser={onLogoutUser}
                  userNanoId={currentUser.nanoId}
                />
              </Fragment>
            ) : null}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default memo<PrimaryMenuProps>(PrimaryMenu);
