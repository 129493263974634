export default {
  addToFavorites: 'Добавить в избранные',
  any: 'Любой',
  and: 'и',
  artists: 'Artists',
  assignAsCreator: 'Назначить постановщиком',
  attach: 'Прикрепить',
  backtrace: 'Отладочная информация',
  cancel: 'Отмена',
  caution: 'Осторожно!',
  clear: 'Очистить',
  clearSelection: 'Очистить выбор',
  client: 'Клиент',
  clientDebt: 'Долг клиента',
  clients: 'Клиенты',
  close: 'Закрыть',
  confirm: 'Подтвердить',
  copyLink: 'Копировать ссылку',
  create: 'Создать',
  debt: 'Долг',
  delete: 'Удалить',
  delete_filter: 'Удалить фильтр',
  description: 'Описание',
  download: 'Скачать',
  errorMessage: 'Сообщение об ошибке',
  expand_view: 'Открыть просмотр',
  favorite: 'Избранное',
  filterFavorite: 'Фильтровать избранные',
  flagged: 'Отмеченное',
  from: 'От',
  generate: 'Сгенерировать',
  hide: 'Скрыть',
  hideList: 'Скрыть список',
  homepage: 'Вернуться на главную',
  info: 'Информация',
  invisibleForClient: 'Спрятано от клиенов',
  invisibleForWorker: 'Спрятано от сотрудников',
  invite_user: 'Пригласить пользователя',
  log_out: 'Выйти',
  login_page: 'Перейти на страницу авторизации',
  members: 'Участники',
  membersGenitiveLowerCase: 'участников',
  model: 'Модель',
  new: 'Новый',
  no: 'Нет',
  noResultsYet: 'Нет результатов',
  noSearchResults: 'Ничего не найдено',
  of: 'из',
  open_in_new_tab: 'Открыть в новой вкладке',
  or: 'Или',
  pinned: 'Закрепленное',
  postMessage: 'Отправить сообщение',
  processing: 'Обработка...',
  remove: 'Удалить',
  removeFromFavorites: 'Убрать из избранных',
  reset_password: 'Сбросить пароль',
  save: 'Сохранить',
  saveFilter: 'Сохранить фильтр',
  savedFilters: 'Сохранённые фильтры',
  search: 'Поиск',
  searchResults: 'Результаты поиска',
  search_user_by_name: 'Поиск по имени пользователя',
  searching: 'Поиск...',
  secondsGenitive: 'секунд',
  select: 'Выбрать',
  selectToAddToTheProject: 'Выбрать, чтобы добавить в проект',
  selected: 'Выбрано',
  sendInstructions: 'Отправить инструкции',
  sendToAllTasks: 'Отправить во все задачи',
  setDueDate: 'Установить дату завершения',
  settings: 'Настройки',
  showList: 'Показать список',
  showMore: 'Показать еще',
  showing: 'Показано',
  sign_out: 'Выйти',
  sku: 'SKU',
  submit: 'Отправить',
  to: 'До',
  total: 'Итог',
  updateImageVersions: 'Обновить версии изображения',
  viewAll: 'Посмотреть все',
  viewAllMembers: 'Посмотреть всех участников',
  viewProfile: 'Просмотреть профиль',
  visibleForClient: 'Видимо для клиента',
  workers: 'Сотрудники',
  yes: 'Да',
  system: 'Системная',
  colorSchemeDark: 'Тёмная',
  colorSchemeLight: 'Светлая',
  colorScheme: 'Цветовая схема',
  timer: {
    days: 'дней',
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд'
  },
  show_n_selected_items: {
    show: 'Показать',
    selected_items: 'выбранных элементов'
  }
};
