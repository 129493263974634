import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TeamsKeyType {
  list: LocalesKey;
  singular: LocalesKey;
}

export const teamsKeys = keyPathMirror<TeamsKeyType, string>(
  {
    list: null,
    singular: null
  },
  'models.teams.'
);
