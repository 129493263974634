import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MaterialsKeyType {
  plural: LocalesKey;
  attach: LocalesKey;
}

export const materialsKeys = keyPathMirror<MaterialsKeyType, string>(
  {
    plural: null,
    attach: null
  },
  'models.materials.'
);
