import React, { ReactNode } from 'react';

import { HeadTitle } from '../../../helpers/HeadTitle';

interface AuthAppLayoutProps {
  children: ReactNode;
}

function AuthAppLayout({ children }: AuthAppLayoutProps) {
  return (
    <>
      <HeadTitle />
      <div className="relative flex flex-col-reverse sm:flex-row w-full h-full overflow-hidden">
        <div
          id="main-section"
          className="flex-1 flex flex-col overflow-hidden z-0 relative"
        >
          <div className="relative flex-1 z-0">
            <div className="absolute inset-0 flex overflow-hidden">
              <main
                className="flex-1 relative overflow-y-auto focus:outline-none"
                tabIndex={-1}
              >
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                  <div className="sm:mx-auto sm:w-full sm:max-w-md py-8 px-4 sm:px-10">
                    {children}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthAppLayout;
