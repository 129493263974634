import React from 'react';

import { IconProps } from '../types';

function CollectionSolidIcon({ className, id }: IconProps) {
  return (
    <div className="mr-2">
      <svg
        id={id}
        xmlns="http://www.w3.org/2000/svg"
        className={className || 'h-5 w-5'}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
      </svg>
    </div>
  );
}

export default CollectionSolidIcon;
