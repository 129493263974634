import React from 'react';
import cl from 'classnames';

import { ClassName, I18nText } from '../../../../../../types';
import { IconsEnum } from '../../../../../../assets/icons/types';

import { useFeedBackForm } from '../../../../../../main/feedBacks/components/FeedBackForm/hooks/useFeedBackForm';
import { FeedBackForm } from '../../../../../../main/feedBacks/components/FeedBackForm';

import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { SimpleModalButton } from '../../../../../../helpers/buttons/SimpleModalButton';

import { words } from '../../../../../../locales/keys';

const CONTACT_SUPPORT_FORM = 'contact-support-form';

interface ContactSupportModalButtonProps {
  isAuthenticated?: boolean;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
}

function ContactSupportModalButton({
  isAuthenticated,
  className,
  iconClassName,
  icon,
  i18nText
}: ContactSupportModalButtonProps) {
  const {
    registerFields,
    createFeedBackLoading,
    createFeedBackErrorMessage,
    createFeedBackReset,
    handleSubmitCreateFeedBack,
    resetCreateFeedBackForm,
    validationErrors
  } = useFeedBackForm({ isAuthenticated });

  return (
    <SimpleModalButton
      className={cl(
        className ||
          'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      )}
      iconClassName={iconClassName}
      i18nSubmitText={words.send}
      icon={icon}
      i18nText={i18nText}
      i18nTitle={words.contactSupport}
      disabled={createFeedBackLoading}
      onOpen={resetCreateFeedBackForm}
      onSubmit={handleSubmitCreateFeedBack}
      onCancel={createFeedBackReset}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      tooltipI18nText={words.contactSupport}
      tooltipSingleton
    >
      <FeedBackForm
        form={CONTACT_SUPPORT_FORM}
        registerName={registerFields.registerName}
        registerEmail={registerFields.registerEmail}
        registerSubject={registerFields.registerSubject}
        registerBody={registerFields.registerBody}
        validationErrorName={validationErrors.feedBackNameValidationError}
        validationErrorEmail={validationErrors.feedBackEmailValidationError}
        validationErrorSubject={validationErrors.feedBackSubjectValidationError}
        validationErrorBody={validationErrors.feedBackBodyValidationError}
        isAuthenticated={isAuthenticated}
        disabled={createFeedBackLoading}
      />
      <AlertMessage addClassName="mt-4" message={createFeedBackErrorMessage} />
    </SimpleModalButton>
  );
}

export default ContactSupportModalButton;
