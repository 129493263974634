export default {
  action: 'Action',
  actionPlural: 'Actions',
  account: 'Account',
  additionalEmail1: 'Additional email #1',
  additionalEmail2: 'Additional email #2',
  additionalInformation: 'Additional Information',
  agreedHourlyRate: 'Agreed hourly rate',
  amount: 'Amount',
  android: 'Android',
  blocked: 'Blocked',
  businessEmail: 'Business Email',
  cells: 'Cells',
  commission: 'Commission',
  creditCommission: 'Credit commission',
  financeRole: 'Finance role',
  hostingCommission: 'Hosting commission',
  balance: 'Balance',
  burning: 'Burning',
  category: 'Category',
  city: 'City',
  clientPrepayment: 'Client prepayment',
  clientStatus: 'Client status',
  clientManagers: 'Client managers',
  coldSource: 'Cold source',
  colorTag: 'Color tag',
  company: 'Company',
  companySize: 'Company size',
  country: 'Country',
  created: 'Created',
  createdAt: 'Created at',
  currency: 'Currency',
  defaultBillingAddress: 'Default billing address',
  defaultPayoneerAccount: 'Default Payoneer account',
  danger: 'Danger',
  deferredPaymentDays: 'Deferred payment (days)',
  description: 'Description',
  discount: 'Discount',
  expectedPaymentDate: 'Expected payment date',
  expectedPaymentAmount: 'Expected payment amount',
  estimateCost: 'Estimate cost',
  experience: 'Experience',
  financeTags: 'Finance tags',
  firstPaymentDate: 'First payment date',
  implementationDate: 'Due date',
  implementedAt: 'Implemented',
  integration: 'Integration',
  email: 'Email',
  emailAddress: 'Email address',
  filterName: 'Filter name',
  frontend: 'Frontend',
  fullName: 'Full Name',
  groups: 'Groups',
  projectManager: 'Project Manager',
  projectManagers: 'Project managers',
  providedFullName: 'Provided Full Name',
  providedFullNameEn: 'Provided Full Name (En)',
  providedFullNameUk: 'Provided Full Name (Uk)',
  id: 'Id',
  ios: 'iOS',
  isMentor: 'Is Mentor',
  kind: 'Kind',
  lastActivityTime: 'Last activity time',
  lastActivity: 'Last activity',
  lastPaymentDate: 'Last payment date',
  manualClientStatus: 'Manual client status ',
  marketingSource: 'Marketing source',
  maxPrice: 'Max price',
  mentor: 'Mentor',
  body: 'Body',
  bodyEn: 'Body (En)',
  bodyUk: 'Body (Uk)',
  minPrice: 'Min price',
  myProjects: 'My projects',
  myTasks: 'My tasks',
  name: 'Name',
  nameEn: 'Name (En)',
  nameUk: 'Name (Uk)',
  surname: 'Surname',
  surnameEn: 'Surname (En)',
  surnameUk: 'Surname (Uk)',
  nda: 'NDA',
  noGo: 'No Go',
  paymentType: 'Payment type',
  payoneerId: 'Payoneer ID (Individual)',
  payoneerIdPe: 'Payoneer ID (PE)',
  password: 'Password',
  phone: 'Phone',
  position: 'Position',
  preferableCurrency: 'Preferable currency',
  prepayment: 'Prepayment',
  price: 'Price',
  proficiencyLevel: 'Proficiency Level',
  qualifications: 'Qualifications',
  rankedName: 'Ranked name',
  rateEducationPoint: 'K edp',
  realDeclineReasons: 'Real decline reasons',
  referer: 'Referer',
  role: 'Role',
  roles: 'Roles',
  registrationDate: 'Registration date',
  source: 'Source',
  sourceFilesCount: 'Source files',
  specializations: 'Specializations',
  state: 'State',
  started: 'Started',
  startDate: 'Start date',
  status: 'Status',
  street: 'Street',
  subCategories: 'Subcategories',
  subject: 'Subject',
  tasksPrice: 'Tasks price',
  title: 'Title',
  type: 'Type',
  totalBalance: 'Total balance',
  uuid: 'UUID',
  nanoId: 'NanoID',
  team: 'Team',
  width: 'Width',
  height: 'Height',
  dpi: 'DPI',
  fileFormat: 'File format',
  hours: 'Hours',
  minutes: 'Minutes',
  updatedAt: 'Updated at',
  user: 'User',
  wig: 'WIG',
  webSite: 'Website',
  zipCode: 'Zip code'
};
