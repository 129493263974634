import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TasksKeyType {
  addTags: LocalesKey;
  all: LocalesKey;
  duplicate: LocalesKey;
  hide: LocalesKey;
  list: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  new: LocalesKey;
  create: LocalesKey;
  current: LocalesKey;
  changeStatus: LocalesKey;
  create_new: LocalesKey;
  view: LocalesKey;
  name: LocalesKey;
  description: LocalesKey;
  downloadResults: LocalesKey;
  areYouSureYouWantLeaveTaskThisActionCannotBeUndone: LocalesKey;
  add: LocalesKey;
  addDescription: LocalesKey;
  addName: LocalesKey;
  buttons: {
    accept: LocalesKey;
    approve: LocalesKey;
    cancel: LocalesKey;
    checkIn: LocalesKey;
    pause: LocalesKey;
    pendingMoreInfo: LocalesKey;
    restore: LocalesKey;
    sendToRevision: LocalesKey;
  };
  editDescription: LocalesKey;
  import: LocalesKey;
  inviteToMultipleTasks: LocalesKey;
  inviteUser: LocalesKey;
  isClosed: LocalesKey;
  noPlural: LocalesKey;
  pleaseRate: LocalesKey;
  finishTasks: LocalesKey;
  finishMultipleTasks: LocalesKey;
  results: LocalesKey;
  singularAttachments: LocalesKey;
  allAttachments: LocalesKey;
  multiple: LocalesKey;
  mute: LocalesKey;
  search: LocalesKey;
  searchByTaskName: LocalesKey;
  selectSingular: LocalesKey;
  sendInvoice: LocalesKey;
  setDueDate: LocalesKey;
  status: LocalesKey;
  states: {
    qa: LocalesKey;
    buttons: {
      accept: LocalesKey;
      approve: LocalesKey;
      cancel: LocalesKey;
      checkIn: LocalesKey;
      pause: LocalesKey;
      pendingMoreInfo: LocalesKey;
      restore: LocalesKey;
      sendToRevision: LocalesKey;
    };
  };
  submitAsTaskResult: LocalesKey;
  submitResults: LocalesKey;
  submitSourceFiles: LocalesKey;
  submittedResults: LocalesKey;
  toProject: LocalesKey;
  findResultsInAttachments: LocalesKey;
  optionalWhenYouExpectTheTaskToBeFinished: LocalesKey;
  leave: LocalesKey;
  tags: LocalesKey;
  pluralLowerCase: LocalesKey;
  taskName: LocalesKey;
  taskTeam: LocalesKey;
  team: LocalesKey;
  trackTaskTime: LocalesKey;
  trackTimeInMultipleTasks: LocalesKey;
  trackTime: LocalesKey;
  unhide: LocalesKey;
  optional: LocalesKey;
  caution: LocalesKey;
}

export const tasksKeys = keyPathMirror<TasksKeyType, string>(
  {
    addTags: null,
    all: null,
    duplicate: null,
    hide: null,
    list: null,
    plural: null,
    singular: null,
    new: null,
    changeStatus: null,
    create: null,
    create_new: null,
    current: null,
    view: null,
    name: null,
    description: null,
    downloadResults: null,
    add: null,
    areYouSureYouWantLeaveTaskThisActionCannotBeUndone: null,
    addDescription: null,
    addName: null,
    buttons: {
      accept: null,
      approve: null,
      cancel: null,
      checkIn: null,
      pause: null,
      pendingMoreInfo: null,
      restore: null,
      sendToRevision: null
    },
    editDescription: null,
    inviteToMultipleTasks: null,
    inviteUser: null,
    import: null,
    isClosed: null,
    noPlural: null,
    pleaseRate: null,
    finishTasks: null,
    finishMultipleTasks: null,
    results: null,
    search: null,
    searchByTaskName: null,
    selectSingular: null,
    sendInvoice: null,
    setDueDate: null,
    singularAttachments: null,
    allAttachments: null,
    multiple: null,
    mute: null,
    states: {
      qa: null,
      buttons: {
        accept: null,
        approve: null,
        cancel: null,
        checkIn: null,
        pause: null,
        pendingMoreInfo: null,
        restore: null,
        sendToRevision: null
      }
    },
    status: null,
    submitAsTaskResult: null,
    submitResults: null,
    submitSourceFiles: null,
    submittedResults: null,
    toProject: null,
    findResultsInAttachments: null,
    optionalWhenYouExpectTheTaskToBeFinished: null,
    leave: null,
    tags: null,
    taskName: null,
    taskTeam: null,
    pluralLowerCase: null,
    team: null,
    trackTaskTime: null,
    trackTimeInMultipleTasks: null,
    trackTime: null,
    unhide: null,
    optional: null,
    caution: null
  },
  'models.tasks.'
);
