import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import {
  LogoutUserGqlQuery,
  LogoutUserGqlStatus,
  LogoutUserGqlError
} from '../../authTypes';

import { useAuth } from '../useAuth';

import { useDeleteQuery } from '../../../main/common/hooks/base/reactQuery/useDeleteQuery';
import { generateNanoId } from '../../../utils/generateNanoId';
import { LocalForage } from '../../../utils/LocalForage';

interface LogoutUserInput {
  clientMutationId: string;
}

interface LogoutUserOptions {
  query: LogoutUserGqlQuery;
}

export interface LogoutUserResponse {
  logoutUser: {
    status: LogoutUserGqlStatus;
  };
}

export interface LogoutUserError {
  fullMessages: LogoutUserGqlError;
}

const action = 'logoutUser';

function useLogoutUser({ query }: LogoutUserOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<LogoutUserInput, LogoutUserResponse, LogoutUserError>({
    action,
    query
  });

  const { deauthenticate } = useAuth();
  const queryClient = useQueryClient();

  return {
    logoutUserData: deleteQueryData,
    logoutUserError: deleteQueryError,
    logoutUserLoading: deleteQueryLoading,
    logoutUserErrorMessage: deleteQueryErrorMessage,
    logoutUser: useCallback<() => Promise<LogoutUserResponse>>(
      () =>
        deleteQuery({ clientMutationId: generateNanoId() }).then((response) => {
          if (response?.[action]?.status === 'success') {
            deauthenticate();
            LocalForage.clear();
            queryClient.clear();
          }

          return response;
        }),
      [deleteQuery, deauthenticate, queryClient]
    ),
    logoutUserReset: deleteQueryReset
  };
}

export default useLogoutUser;
