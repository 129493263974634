import compact from 'lodash/compact';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import { DeleteItemGqlQuery } from '../../../../../../../types';

import { graphQLClient } from '../../graphQLClients';

import { productionConsole } from '../../../../../../../utils/productionConsole';

interface DeleteItemProps<DeleteItemInput> {
  action: string;
  query: DeleteItemGqlQuery;
  queryInput: DeleteItemInput;
}

const successQueryStatuses = ['deleted', 'success'];

export function deleteItem<DeleteItemInput, DeleteItemResponse>({
  action,
  query,
  queryInput
}: DeleteItemProps<DeleteItemInput>) {
  productionConsole(
    'log',
    'deleteItem',
    { action, queryInput },
    last(uniq(compact(query.match(/(mutation\s.+)\(/)))),
    JSON.stringify(queryInput)
  );

  return new Promise<DeleteItemResponse>((resolve, reject) => {
    return graphQLClient
      .request<DeleteItemResponse, DeleteItemInput>(query, queryInput)
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        reject(get(response, `${action}.errors`));
      })
      .catch((reason) => reject(reason));
  });
}
