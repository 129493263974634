export default {
  plural: 'Bills',
  pay: 'Pay',
  types: {
    rietumu: 'Rietumu',
    payoneer: 'Payoneer',
    payoneerCheckout: 'Payoneer Checkout',
    twoCheckout: '2checkout',
    fondy: 'Fondy.eu',
    stripe: 'Stripe',
    easypay: 'Easypay',
    invoice: 'Invoice',
    raiffeisenBank: 'Raiffeisen bank',
    bankAccountAndrey: 'Cash',
    system: 'Archivizer',
    client: "Prepaid client's account",
    cancelPayment: 'Cancel payment',
    amount: 'From client account',
    cancelAmount: 'Return to client account',
    debitAccount: 'Pay from CAD',
    cancelDebitAccount: 'Return to CAD',
    bonus: 'Pay from CAB',
    cancelBonus: 'Return to CAB',
    task: 'Project',
    cancelTask: 'Return to Project'
  },
  buttonTypes: {
    account: 'Only account',
    card: 'Only card',
    cardAccount: 'Both buttons'
  },
  statuses: {
    canceled: 'Canceled',
    notPaid: 'Not paid',
    paid: 'Paid',
    paying: 'Paying'
  },
  invoiceHasBeenSuccessfullyPaid: 'Invoice has been successfully paid.',
  payoneerCheckoutCancel: 'Payoneer Checkout cancel',
  paymentFailed: 'Payment failed',
  paymentProcessing: 'Payment processing..',
  paymentSuccessful: 'Payment successful',
  searchByProjectName: 'Search by project name',
  selectButtons: 'Select buttons',
  selectSource: 'Select source',
  payBills: 'Pay bills',
  unpaid: 'Unpaid Bills',
  totalAmount: 'Total bills amount',
  totalUnpaidAmount: 'Total unpaid amount',
  transactionHasFailedDueToSome:
    'Transaction has failed due to some technical error. Please try again.'
};
