import { gql } from 'graphql-request';

import {
  UserCreatedAt,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserLocale,
  UserNanoID,
  UserPermissionsAction,
  UserPermissionsUUID,
  UserUUID,
  UserClient
} from '../../main/users/usersTypes';

export interface FetchCurrentUserQueryResponse {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  locale: UserLocale;
  createdAt: UserCreatedAt;
  client: UserClient;
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  };
  permissions: {
    uuid: UserPermissionsUUID;
    action: UserPermissionsAction;
  }[];
}

export const FETCH_CURRENT_USER = gql`
  query FetchCurrentUser {
    currentUser {
      id
      uuid
      nanoId
      fullName
      locale
      createdAt
      client
      image {
        uuid
        file
      }
      permissions {
        uuid
        action
      }
    }
  }
`;
