import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ReportsKeyType {
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  requeue: LocalesKey;
  total: LocalesKey;
  initialized: LocalesKey;
  processing: LocalesKey;
  failed: LocalesKey;
  completed: LocalesKey;
  errorMessage: LocalesKey;
  types: {
    projectsGlobalReport: LocalesKey;
    projectsGlobalLite1Report: LocalesKey;
    projectsGlobalLite2Report: LocalesKey;
    projectsGlobalLite3Report: LocalesKey;
    tasksGlobalReport: LocalesKey;
    tasksGlobalLiteReport: LocalesKey;
    tasksGlobalLite1Report: LocalesKey;
    tasksGlobalLite2Report: LocalesKey;
    tasksGlobalLite3Report: LocalesKey;
    clientsGlobalReport: LocalesKey;
    clientsGlobalLiteReport: LocalesKey;
    clientsGlobalLite1Report: LocalesKey;
    clientsPulseReport: LocalesKey;
    workersPulseReport: LocalesKey;
    workersGlobalReport: LocalesKey;
    rangeRevenueRecognitionReport: LocalesKey;
    totalClientPaymentsReport: LocalesKey;
    clientsRevenueReport: LocalesKey;
    workersRevenueReport: LocalesKey;
    clientToWorkerPaymentsReport: LocalesKey;
    receivedPaymentsReport: LocalesKey;
    workersTimeReport: LocalesKey;
    revenueShareReport: LocalesKey;
    clientsAveragePaymentFrequencyReport: LocalesKey;
    workersDownloadsReport: LocalesKey;
    workersDownloadsDetailedReport: LocalesKey;
    globalProjectsBalanceReport: LocalesKey;
    globalTasksBalanceReport: LocalesKey;
    clientsBalancesReport: LocalesKey;
    workersBalancesReport: LocalesKey;
    suspendedTasksReport: LocalesKey;
    globalProjectsDebtReport: LocalesKey;
    globalTasksDebtReport: LocalesKey;
    globalClientsDebtReport: LocalesKey;
    globalWorkersDebtReport: LocalesKey;
    globalTeamsAndClientsReport: LocalesKey;
    tasksAccountReceivableReport: LocalesKey;
    rfaReport: LocalesKey;
    libraryUsageReport: LocalesKey;
    clientsFilesUsageReport: LocalesKey;
    clientsRequirementsFilesReport: LocalesKey;
    blockedUsersAllocationsReport: LocalesKey;
    burnedTasksReport: LocalesKey;
    tasksCheckListsReport: LocalesKey;
    replyRateReport: LocalesKey;
    postponedTermsReport: LocalesKey;
    taggedTasksReport: LocalesKey;
    actqReport: LocalesKey;
    managersDynamicsReport: LocalesKey;
    doubleDistributionsReport: LocalesKey;
    minusDistributionsReport: LocalesKey;
  };
}

export const reportsKeys = keyPathMirror<ReportsKeyType, string>(
  {
    plural: null,
    create: null,
    delete: null,
    requeue: null,
    total: null,
    initialized: null,
    processing: null,
    failed: null,
    completed: null,
    errorMessage: null,
    types: {
      projectsGlobalReport: null,
      projectsGlobalLite1Report: null,
      projectsGlobalLite2Report: null,
      projectsGlobalLite3Report: null,
      tasksGlobalReport: null,
      tasksGlobalLiteReport: null,
      tasksGlobalLite1Report: null,
      tasksGlobalLite2Report: null,
      tasksGlobalLite3Report: null,
      clientsGlobalReport: null,
      clientsGlobalLiteReport: null,
      clientsGlobalLite1Report: null,
      clientsPulseReport: null,
      workersPulseReport: null,
      workersGlobalReport: null,
      rangeRevenueRecognitionReport: null,
      totalClientPaymentsReport: null,
      clientsRevenueReport: null,
      workersRevenueReport: null,
      clientToWorkerPaymentsReport: null,
      receivedPaymentsReport: null,
      workersTimeReport: null,
      revenueShareReport: null,
      clientsAveragePaymentFrequencyReport: null,
      workersDownloadsReport: null,
      workersDownloadsDetailedReport: null,
      globalProjectsBalanceReport: null,
      globalTasksBalanceReport: null,
      clientsBalancesReport: null,
      workersBalancesReport: null,
      suspendedTasksReport: null,
      globalProjectsDebtReport: null,
      globalTasksDebtReport: null,
      globalClientsDebtReport: null,
      globalWorkersDebtReport: null,
      globalTeamsAndClientsReport: null,
      tasksAccountReceivableReport: null,
      rfaReport: null,
      libraryUsageReport: null,
      clientsFilesUsageReport: null,
      clientsRequirementsFilesReport: null,
      blockedUsersAllocationsReport: null,
      burnedTasksReport: null,
      tasksCheckListsReport: null,
      replyRateReport: null,
      postponedTermsReport: null,
      taggedTasksReport: null,
      actqReport: null,
      managersDynamicsReport: null,
      doubleDistributionsReport: null,
      minusDistributionsReport: null
    }
  },
  'models.reports.'
);
