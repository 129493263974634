import { createPortal } from 'react-dom';

import Popover, { PopoverProps } from './Popover';

const PopoverWithPortal = (props: PopoverProps) => {
  if (typeof window === 'undefined' || !window?.document?.body) {
    return null;
  }
  return createPortal(<Popover {...props} />, window.document.body);
};

export default PopoverWithPortal;
