import React, { useCallback, useState } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../tooltips/TooltipSingletonTarget';
import { Translate } from '../../Translate';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type PureTooltipIconButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureTooltipIconButtonHelperDefaultProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: PureTooltipIconButtonHelperOnClick;
  onMouseEnter?: () => void;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

export interface PureTooltipIconButtonHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureTooltipIconButtonHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureTooltipIconButtonHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

interface PureTooltipIconButtonHelperWithTooltipI18nTextProps {
  tooltipI18nText: I18nText;
  tooltipText?: never;
}

interface PureTooltipIconButtonHelperWithTooltipTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
}

type PureTooltipIconButtonHelperProps =
  PureTooltipIconButtonHelperDefaultProps &
    (
      | PureTooltipIconButtonHelperIconProps
      | PureTooltipIconButtonHelperWithI18nProps
      | PureTooltipIconButtonHelperWithTextProps
    ) &
    (
      | PureTooltipIconButtonHelperWithTooltipI18nTextProps
      | PureTooltipIconButtonHelperWithTooltipTextProps
    );

function PureTooltipIconButtonHelper({
  id,
  className,
  style,
  disabled,
  icon = null,
  iconClassName,
  tooltipI18nText,
  tooltipText,
  tooltipPlacement,
  tooltipSingleton,
  onClick,
  onMouseEnter,
  i18nTextClassName,
  i18nText,
  text
}: PureTooltipIconButtonHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback<PureTooltipIconButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const tooltipView = tooltipI18nText ? (
    <Tooltip
      withArrow
      referenceElement={referenceTooltipElement}
      tooltipI18nText={tooltipI18nText}
      placement={tooltipPlacement}
    />
  ) : (
    <Tooltip
      withArrow
      referenceElement={referenceTooltipElement}
      tooltipText={tooltipText as string}
      placement={tooltipPlacement}
    />
  );

  const tooltipSingletonView = tooltipI18nText ? (
    <TooltipSingletonTarget
      referenceElement={referenceTooltipElement}
      tooltipI18nText={tooltipI18nText}
    />
  ) : (
    <TooltipSingletonTarget
      referenceElement={referenceTooltipElement}
      tooltipText={tooltipText as string}
    />
  );

  return (
    <button
      id={id}
      className={className}
      style={style}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      ref={setReferenceTooltipElement}
      type="button"
    >
      {icon ? (
        <Icon
          className={iconClassName}
          icon={icon}
          id={id ? `${id}-icon` : undefined}
        />
      ) : null}
      {icon && i18nText ? ' ' : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </button>
  );
}

export default PureTooltipIconButtonHelper;
