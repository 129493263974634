export default {
  access: 'Access',
  accounts: 'Accounts',
  averageEvaluation: 'Avg. evaluation',
  balance: 'Balance',
  basicInfo: 'Basic info',
  debt: 'Debt',
  description: 'Description',
  documents: 'Documents',
  editProfilePicture: 'Edit profile picture',
  files: 'Files',
  marketingInfo: 'Marketing info',
  rate: 'Rate',
  ratio: 'Ratio',
  records: 'Records',
  requirements: 'Requirements',
  singular: 'Profile',
  teamAndPosition: 'Team and position',
  totalHours: 'Total hours',
  totalPayments: 'Total payments',
  totalProjects: 'Total projects',
  totalTasks: 'Total tasks',
  userMessages: 'User messages',
  userResults: 'User results'
};
