import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface OperationsKeyType {
  record: LocalesKey;
  status: LocalesKey;
  recordedDate: LocalesKey;
  dueDate: LocalesKey;
  completedDate: LocalesKey;
}

export const operationsKeys = keyPathMirror<OperationsKeyType, string>(
  {
    record: null,
    status: null,
    recordedDate: null,
    dueDate: null,
    completedDate: null
  },
  'models.operations.'
);
