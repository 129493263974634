import { NextPageContext } from 'next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { universalLanguageDetect } from '@unly/universal-language-detector';

import en from '../../locales/en';
import ru from '../../locales/ru';
import uk from '../../locales/uk';

const EN_LANG = 'en';
const RU_LANG = 'ru';
const UK_LANG = 'uk';

const DEFAULT_LANG = EN_LANG;

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      ru: {
        translation: ru
      },
      uk: {
        translation: uk
      }
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['htmlTag'],
      caches: []
    }
  });

const determineLanguage = (req) =>
  universalLanguageDetect({
    supportedLanguages: [EN_LANG, RU_LANG, UK_LANG],
    fallbackLanguage: DEFAULT_LANG,
    acceptLanguageHeader: req.headers['accept-language']
  });

export const languageServerDetection = (ctx: NextPageContext): void => {
  if (typeof window === 'undefined') {
    const language = determineLanguage(ctx.req);
    i18n.changeLanguage(language);
  }
};

export default i18n;
