import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AccountsKeyType {
  plural: LocalesKey;
  createAnAccount: LocalesKey;
  createNewAccount: LocalesKey;
  createArtistAccount: LocalesKey;
  createWorkerAccount: LocalesKey;
  forgotYourPasswordQuestion: LocalesKey;
  signIn: LocalesKey;
  signUp: LocalesKey;
  signInToYourAccount: LocalesKey;
}

export const accountsKeys = keyPathMirror<AccountsKeyType, string>(
  {
    plural: null,
    createAnAccount: null,
    createNewAccount: null,
    createArtistAccount: null,
    createWorkerAccount: null,
    forgotYourPasswordQuestion: null,
    signIn: null,
    signUp: null,
    signInToYourAccount: null
  },
  'models.accounts.'
);
