export default {
  plural: 'Invitations',
  accept: 'Accept',
  acceptAll: 'Accept all',
  acceptSelected: 'Accept selected',
  decline: 'Decline',
  declineAll: 'Decline all',
  declineSelected: 'Decline selected',
  search: 'Search invitations by task name',
  areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone:
    'Are you sure you want to accept all task invitations? This action cannot be undone.',
  areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone:
    'Are you sure you want to decline all task invitations? This action cannot be undone.',
  areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone:
    'Are you sure you want to decline task invitation? This action cannot be undone.'
};
