import { ReactNode, useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import compact from 'lodash/compact';

import { PositionStyleEnum } from '../../types';

import { PopoverCloseOnEsc } from './helpers/PopoverCloseOnEsc';
import { PopoverCloseOnOuterClick } from './helpers/PopoverCloseOnOuterClick';

import { PopoverPlacement, preventOverflowModifier } from './popoverConstants';

export interface PopoverProps {
  arrowClassName?: string;
  children?: ReactNode;
  className?: string;
  closeOnEsc?: boolean;
  closeOnOuterClick?: boolean;
  closePopover?: () => void;
  distanceOffset?: number;
  placement?: PopoverPlacement;
  positionStyle?: PositionStyleEnum;
  referenceElement?: HTMLElement | null;
  withArrow?: boolean;
}

const Popover = ({
  arrowClassName,
  children,
  className,
  closeOnEsc = true,
  closeOnOuterClick = true,
  closePopover,
  distanceOffset = 10,
  placement = PopoverPlacement.BOTTOM,
  positionStyle,
  referenceElement = null,
  withArrow
}: PopoverProps) => {
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const options = useMemo(() => {
    return {
      placement,
      modifiers: compact([
        {
          name: 'offset',
          options: {
            offset: [0, distanceOffset] // distanceOffset is distance from referenceElement
          }
        },
        preventOverflowModifier,
        withArrow
          ? {
              name: 'arrow',
              options: {
                element: arrowElement
              }
            }
          : null
      ])
    };
  }, [placement, distanceOffset, withArrow, arrowElement]);

  const { styles, attributes, state } = usePopper(
    referenceElement,
    popoverElement,
    options
  );

  const popperStyles = positionStyle
    ? { ...styles.popper, position: positionStyle }
    : styles.popper;

  if (!children) {
    return null;
  }

  return (
    <>
      <div
        {...attributes.popper}
        className={className}
        ref={setPopoverElement}
        style={popperStyles}
      >
        {withArrow ? (
          <div
            ref={setArrowElement}
            className={arrowClassName}
            style={styles.arrow}
            data-placement={state?.placement}
          />
        ) : null}
        {children}
        {closeOnEsc && closePopover ? (
          <PopoverCloseOnEsc closePopover={closePopover} />
        ) : null}
        {closeOnOuterClick && closePopover ? (
          <PopoverCloseOnOuterClick
            closePopover={closePopover}
            popoverElement={popoverElement}
            referenceElement={referenceElement}
          />
        ) : null}
      </div>
    </>
  );
};

export default Popover;
