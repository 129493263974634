export default {
  account: 'Рахунок проєкту',
  addDescription: 'Додати опис проєкту',
  addTags: 'Додати теги проєкту',
  changeEstimateCost: 'Змінити очікувану вартість проєкту',
  create: 'Створити проєкт',
  caution: 'Обережно!',
  create_new: 'Створити новий проєкт',
  description: 'Опис проєкту',
  destination: 'Цільовий проєкт',
  details: 'Подробиці проєкту',
  download_excel: 'Завантажити таблицю з даними всіх проєктів',
  download_selected_excel: 'Завантажити таблицю з даними обраних проєктів',
  edit_description: 'Змінити опис проєкту',
  edit_details: 'Змінити подробиці',
  enter_name: 'Введіть назву проєкту',
  estimate_cost: 'Очікувана вартість проєкту',
  favorite: 'Вподобані проєкти',
  members: 'Учасники проєкту',
  messages: 'Повідомлення проекту',
  nallocated_funds: 'Нерозподілені кошти проєкту',
  name: 'Назва проєкту',
  new: 'Новий проєкт',
  no: 'Проєкти відсутні',
  plural: 'Проєкти',
  pluralLowerCase: 'задачі',
  plural_genitive_lower_case: 'проєктів',
  profile: 'Типова задача',
  projectName: 'Назва Проєкту',
  remove_from: 'Видалити з проєкту',
  root: 'Корінь проєкту',
  search: 'Пошук проєктів',
  searchByProjectName: 'Пошук за назвою проєкту',
  select: 'Обрати проєкт...',
  select_user: 'Обрати учасника проєкту',
  selected_users: 'Обрати учасників проєкту',
  setDueDate: 'Встановити дату завершення проєкту',
  sendInvoice: 'Надіслати рахунок',
  set_cost: 'Встановити вартість проєкту',
  singular: 'Проєкт',
  singular_downcase: 'проєкт',
  status: 'Статус проєкту',
  tags: 'Теги проєкту',
  team: 'Команда проєкту',
  unallocated_funds: 'Нерозподілені кошти проєкту',
  view: 'Дивитися проєкт',
  inviteSelected: 'Запросити обраних',
  inviteUser: 'Запросити учасника',
  inviteToMultipleProjects: 'Запрошення в декілька проектів'
};
