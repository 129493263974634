import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface CategoriesKeyType {
  plural: LocalesKey;
  search: LocalesKey;
  seeAll: LocalesKey;
  seeLess: LocalesKey;
  styles: {
    plural: LocalesKey;
    singular: LocalesKey;
  };
  gammas: {
    singular: LocalesKey;
  };
  tones: {
    singular: LocalesKey;
  };
  materials: {
    plural: LocalesKey;
    singular: LocalesKey;
  };
  brands: {
    plural: LocalesKey;
    search: LocalesKey;
    singular: LocalesKey;
  };
  authors: {
    plural: LocalesKey;
    singular: LocalesKey;
    search: LocalesKey;
  };
  colors: {
    plural: LocalesKey;
    singular: LocalesKey;
  };
  mainColors: {
    plural: LocalesKey;
  };
  accentColors: {
    plural: LocalesKey;
  };
  fileVersionTags: {
    plural: LocalesKey;
  };
  mistakeTypes: {
    plural: LocalesKey;
  };
  collections: {
    plural: LocalesKey;
  };
  statuses: {
    plural: LocalesKey;
  };
  client: {
    select: LocalesKey;
  };
}

export const categoriesKeys = keyPathMirror<CategoriesKeyType, string>(
  {
    plural: null,
    search: null,
    seeAll: null,
    seeLess: null,
    styles: {
      plural: null,
      singular: null
    },
    gammas: {
      singular: null
    },
    tones: {
      singular: null
    },
    materials: {
      plural: null,
      singular: null
    },
    brands: {
      plural: null,
      search: null,
      singular: null
    },
    authors: {
      plural: null,
      singular: null,
      search: null
    },
    colors: {
      plural: null,
      singular: null
    },
    mainColors: {
      plural: null
    },
    accentColors: {
      plural: null
    },
    fileVersionTags: {
      plural: null
    },
    mistakeTypes: {
      plural: null
    },
    collections: {
      plural: null
    },
    statuses: {
      plural: null
    },
    client: {
      select: null
    }
  },
  'models.categories.'
);
