export default {
  action: {
    required: 'Введите действие'
  },
  due_date: {
    inFuture: 'Дата завершения должна быть в будущем',
    invalid: 'Дата завершения не задана или имеет некорректный формат'
  },
  email: {
    required: 'Введите email',
    valid: 'Введите действительный email'
  },
  filterName: {
    required: 'Введите имя фильтра'
  },
  fullName: {
    required: 'Введите полное имя'
  },
  name: {
    required: 'Введите имя'
  },
  password: {
    required: 'Введите пароль',
    valid: 'Пароль должен состоять минимум из 6 символов'
  },
  policy: {
    required: 'Вы должны принять условия'
  },
  required: 'Поле не должно быть пустым.'
};
