export default {
  action: 'Действие',
  android: 'Android',
  blocked: 'Заблокировано',
  burning: 'Горящие',
  colorTag: 'Метка цвета',
  createdAt: 'Создано',
  danger: 'Опасность',
  description: 'Описание',
  financeRole: 'Финансовая роль',
  implementationDate: 'Дата завершения',
  implementedAt: 'Завершено',
  emailAddress: 'Email адрес',
  filterName: 'Имя фильтра',
  frontend: 'Frontend',
  fullName: 'Полное имя',
  id: 'Id',
  ios: 'iOS',
  maxPrice: 'Макс стоимость',
  minPrice: 'Мин стоимость',
  name: 'Имя',
  nameEn: 'Имя (En)',
  nameUk: 'Имя (Uk)',
  body: 'Текст',
  bodyEn: 'Текст (En)',
  bodyUk: 'Текст (Uk)',
  nda: 'НДА',
  password: 'Пароль',
  price: 'Стоимость',
  roles: 'Роли',
  registrationDate: 'Дата регистрации',
  sourceFilesCount: 'Исходники',
  started: 'Начато',
  status: 'Статус',
  tasksPrice: 'Стоимость задач',
  title: 'Название',
  type: 'Тип',
  uuid: 'UUID',
  user: 'Пользователь'
};
