export const enum PopoverPlacement {
  AUTO = 'auto',
  AUTO_END = 'auto-end',
  AUTO_START = 'auto-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start'
}

export const preventOverflowModifier = {
  name: 'preventOverflow',
  options: {
    padding: 12 // min distance from window edge
  }
};
