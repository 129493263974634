import dropRight from 'lodash/dropRight';
import split from 'lodash/split';
import last from 'lodash/last';

import defaultImage from '../../assets/images/placeholders/blank-profile-picture-512@2x.png';

interface FileItemType {
  file: string;
}

class Files {
  static image(item: FileItemType, version?: string): string {
    const url = Files.url(item, version);

    return this.isImage(url) ? url : defaultImage;
  }

  static defaultVersion(): string {
    return 'thumb_160x160';
  }

  static extension(file: string): string {
    return file && file.substr(file.lastIndexOf('.') + 1);
  }

  static isImage(file: string): boolean {
    return /(jpe?g|png|gif|bmp)/i.test(this.extension(file));
  }

  static isTableFile(file: string): boolean {
    return /(csv|xls|ods)/i.test(this.extension(file));
  }

  static isTextFile(file: string): boolean {
    return /(doc?x|txt|odt)/i.test(this.extension(file));
  }

  static is3dMaxModel(file: string): boolean {
    return /(max)/i.test(this.extension(file));
  }

  static isArchive(file: string): boolean {
    return /(zip|rar|7z)/i.test(this.extension(file));
  }

  static isPDF(file: string): boolean {
    return /(pdf)/i.test(this.extension(file));
  }

  static isAudio(file: string): boolean {
    return /(mp3|wav)/i.test(this.extension(file));
  }

  static isVideo(file: string): boolean {
    return /(mp4|mov|avi)/i.test(this.extension(file));
  }

  static url(item: FileItemType, version?: string): string {
    const url = item?.file;

    if (url) {
      return this.urlFromFile(url, version);
    }

    return url;
  }

  static urlFromFile(file: string, version?: string): string {
    if (file && this.isImage(file)) {
      const urlParts = split(file, '/');

      return [
        ...dropRight(urlParts),
        [version || Files.defaultVersion(), last(urlParts)].join('_')
      ].join('/');
    }

    return file;
  }
}

export default Files;
