import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserNanoID } from '../users/usersTypes';

const basePath = '/messages';

export class MessagePath {
  static index() {
    return basePath;
  }

  static myMessages() {
    return `${basePath}/filters/my-messages`;
  }

  static unread() {
    return `${basePath}/filters/new`;
  }

  static onlyClients() {
    return `${basePath}/filters/from-clients`;
  }

  static visible() {
    return `${basePath}/filters/visible`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static userMessages(userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${userNanoId}`;
  }
}
