export enum PrimaryNavTabsEnum {
  DASHBOARD = 'dashboard',
  NOTIFICATIONS = 'notifications',
  TASKS_AND_PROJECTS = 'tasks_and_projects',
  STOCK = 'stock',
  USERS = 'users',
  WORKERS = 'workers',
  CLIENTS = 'clients',
  ADMIN = 'admin'
}
