import startsWith from 'lodash/startsWith';

import { PrimaryNavTabsEnum } from '../../types';

import { BillPath } from '../../../../../main/bills/BillPath';
import { CategoryPath } from '../../../../../main/categories/CategoryPath';
import { LifestylePath } from '../../../../../main/lifestyles/LifestylePath';
import { MainPath } from '../../../../../main/MainPath';
import { MessagePath } from '../../../../../main/messages/MessagePath';
import { PagePath } from '../../../../../main/pages/PagePath';
import { PermissionPath } from '../../../../../main/permissions/PermissionPath';
import { ProductPath } from '../../../../../main/products/ProductPath';
import { ProjectPath } from '../../../../../main/projects/ProjectPath';
import { ReportPath } from '../../../../../main/reports/ReportPath';
import { RolePath } from '../../../../../main/roles/RolePath';
import { SectionPath } from '../../../../../main/sections/SectionPath';
import { SentMessagePath } from '../../../../../main/sentMessages/SentMessagePath';
import { StockPath } from '../../../../../main/stock/StockPath';
import { TaskPath } from '../../../../../main/tasks/TaskPath';
import { UserPath } from '../../../../../main/users/UserPath';

function getCurrentPathTab(pathname: string): PrimaryNavTabsEnum | null {
  if (pathname === MainPath.index()) {
    return PrimaryNavTabsEnum.DASHBOARD;
  }
  // if () {
  //   return PrimaryNavTabsEnum.NOTIFICATIONS;
  // }
  if (
    startsWith(pathname, MessagePath.index()) ||
    startsWith(pathname, ProjectPath.index()) ||
    startsWith(pathname, TaskPath.index())
  ) {
    return PrimaryNavTabsEnum.TASKS_AND_PROJECTS;
  }
  if (
    startsWith(pathname, StockPath.index()) ||
    startsWith(pathname, ProductPath.index()) ||
    startsWith(pathname, LifestylePath.index())
  ) {
    return PrimaryNavTabsEnum.STOCK;
  }
  if (startsWith(pathname, UserPath.index())) {
    return PrimaryNavTabsEnum.USERS;
  }
  if (
    startsWith(pathname, BillPath.index()) ||
    startsWith(pathname, RolePath.index()) ||
    startsWith(pathname, PermissionPath.index()) ||
    startsWith(pathname, SectionPath.index()) ||
    startsWith(pathname, CategoryPath.index()) ||
    startsWith(pathname, SentMessagePath.index()) ||
    startsWith(pathname, ReportPath.index()) ||
    startsWith(pathname, PagePath.index())
  ) {
    return PrimaryNavTabsEnum.ADMIN;
  }
  if (startsWith(pathname, UserPath.workers())) {
    return PrimaryNavTabsEnum.WORKERS;
  }
  if (startsWith(pathname, UserPath.clients())) {
    return PrimaryNavTabsEnum.CLIENTS;
  }

  return null;
}

export default getCurrentPathTab;
