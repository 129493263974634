export default {
  account: 'Счет проекта',
  addDescription: 'Добавить описание проекта',
  addTags: 'Добавить теги проекта',
  caution: 'Осторожно!',
  create_new: 'Создание нового проекта',
  create: 'Создать проект',
  changeEstimateCost: 'Изменить оценочную стоимость проекта',
  description: 'Описание проекта',
  destination: 'Целевой проект',
  details: 'Детали проекта',
  download_excel: 'Скачать документ excel со списком всех проектов',
  download_selected_excel:
    'Скачать документ excel со списком выбранных проектов',
  edit_description: 'Редактировать описание проекта',
  edit_details: 'Редактировать детали проекта',
  enter_name: 'Введите название проекта',
  estimate_cost: 'Оценочная стоимость проекта',
  favorite: 'Избранные проекты',
  members: 'Участники проекта',
  messages: 'Сообщения проекта',
  name: 'Название проекта',
  name_a_z: 'Название проекта (А-Я)',
  name_z_a: 'Название проекта (Я-А)',
  new: 'Новый проект',
  no: 'Проектов нет',
  plural: 'Проекты',
  pluralLowerCase: 'задачи',
  plural_genitive_lower_case: 'проектов',
  profile: 'Типовая задача',
  projectName: 'Название проекта',
  remove_from: 'Удалить из проекта',
  root: 'Корень проекта',
  search: 'Поиск проектов',
  searchByProjectName: 'Поиск по названию проекта',
  select: 'Выберите проект...',
  select_user: 'Выбрать участника проекта',
  selected_users: 'Выбранные участники проекта',
  sendInvoice: 'Отправить счет',
  setDueDate: 'Установить дату завершения проекта',
  set_cost: 'Установить стоимость проекта',
  singular: 'Проект',
  status: 'Статус проекта',
  tags: 'Теги проекта',
  team: 'Команда проекта',
  track_time: 'Списать время в проект',
  unallocated_funds: 'Нераспределенные средства проекта',
  view: 'Просмотреть проект',
  inviteSelected: 'Пригласить выбраных',
  inviteUser: 'Пригласить участника',
  inviteToMultipleProjects: 'Приглашение в несколько проектов'
};
