import accounts from './accounts';
import categories from './categories';
import evaluations from './evaluations';
import images from './images';
import menu from './menu';
import messages from './messages';
import pages from './pages';
import permissions from './permissions';
import products from './products';
import projects from './projects';
import projectMemberInvites from './projectMemberInvites';
import results from './results';
import statuses from './statuses';
import tags from './tags';
import tasks from './tasks';
import transactionTags from './transactionTags';
import teams from './teams';
import users from './users';
import taskMemberInvites from './taskMemberInvites';

export default {
  accounts,
  categories,
  evaluations,
  images,
  menu,
  messages,
  pages,
  permissions,
  products,
  projects,
  projectMemberInvites,
  results,
  statuses,
  tags,
  tasks,
  transactionTags,
  teams,
  users,
  taskMemberInvites
};
