import React, { Fragment, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import cl from 'classnames';

import 'react-loading-skeleton/dist/skeleton.css';

import { ClassName } from '../../types';

interface LoadingProps {
  addClass?: string;
  children?: ReactNode;
  className?: string;
  skeletonClassName?: ClassName;
  ignoreLoader?: ReactNode;
  count?: number;
  loaded?: boolean;
  withoutLoader?: boolean;
  width?: string | number;
  height?: string | number;
}

function LoadingSkeleton({
  addClass,
  children,
  className,
  skeletonClassName,
  ignoreLoader,
  count = 7,
  loaded = false,
  withoutLoader = false,
  width,
  height
}: LoadingProps) {
  if (!loaded && withoutLoader) {
    return null;
  }

  return (
    <Fragment>
      {ignoreLoader}
      {loaded ? (
        children
      ) : (
        <div className={className || cl('m-4', addClass)}>
          <Skeleton
            count={count}
            height={height}
            width={width}
            containerClassName={skeletonClassName}
          />
        </div>
      )}
    </Fragment>
  );
}

export default LoadingSkeleton;
