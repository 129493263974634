export default {
  plural: 'Transactions',
  noPlural: 'No transactions',
  balanceTypes: {
    debitAccount: 'Debit account',
    creditAccount: 'Credit account',
    projectAccount: 'Projects',
    taskAccount: 'Tasks',
    amount: 'Old account',
    prepaidAmount: 'Prepaid account',
    bonusAmount: 'Bonus amount'
  },
  totalBalance: 'Total balance'
};
