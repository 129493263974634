import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TodoItemsKeyType {
  plural: LocalesKey;
}

export const todoItemsKeys = keyPathMirror<TodoItemsKeyType, string>(
  {
    plural: null
  },
  'models.todoItems.'
);
