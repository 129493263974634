export default {
  action: {
    required: 'Введіть дію'
  },
  due_date: {
    inFuture: 'Дата завершення повинна бути в майбутньому',
    invalid: 'Дата завершення недійсна або не встановлена'
  },
  email: {
    required: 'Введіть ваш email, будь ласка',
    valid: 'Введіть дійсний email, будь ласка'
  },
  filterName: {
    required: 'Введіть назву фільтру, будь ласка'
  },
  fullName: {
    required: "Введіть повне ім'я"
  },
  name: {
    required: 'Введіть ім’я, будь ласка'
  },
  password: {
    required: 'Введіть пароль, будь ласка',
    valid: 'Введіть дійсний пароль. Пароль повинен містити не менше 6 символів'
  },
  policy: {
    required:
      'Щоб продовжити, ви мусите погодитися з правилами оплати, повернення коштів та політикою конфіденційності.'
  }
};
