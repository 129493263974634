import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ColorsKeyType {
  plural: LocalesKey;
  attach: LocalesKey;
}

export const colorsKeys = keyPathMirror<ColorsKeyType, string>(
  {
    plural: null,
    attach: null
  },
  'models.colors.'
);
