import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MenuKeyType {
  contactSupport: LocalesKey;
  hide: LocalesKey;
  keepOpen: LocalesKey;
  links: {
    access: LocalesKey;
    active: LocalesKey;
    blocked: LocalesKey;
    favorite: LocalesKey;
    finance: LocalesKey;
    invoices: LocalesKey;
    roles: LocalesKey;
    pages: LocalesKey;
    permissions: LocalesKey;
    people: LocalesKey;
    sections: LocalesKey;
    products: LocalesKey;
    projects: LocalesKey;
    tasks: LocalesKey;
    content: LocalesKey;
    reports: LocalesKey;
    sentMessages: LocalesKey;
    stock: LocalesKey;
    messages: LocalesKey;
  };
  tabs: {
    dashboard: LocalesKey;
    notifications: LocalesKey;
    tasksAndProjects: LocalesKey;
    workspace: LocalesKey;
    stock3d: LocalesKey;
    users: LocalesKey;
    workers: LocalesKey;
    clients: LocalesKey;
    admin: LocalesKey;
  };
}

export const menuKeys = keyPathMirror<MenuKeyType, string>(
  {
    contactSupport: null,
    hide: null,
    keepOpen: null,
    links: {
      access: null,
      active: null,
      blocked: null,
      favorite: null,
      finance: null,
      invoices: null,
      roles: null,
      pages: null,
      permissions: null,
      people: null,
      sections: null,
      products: null,
      projects: null,
      tasks: null,
      content: null,
      reports: null,
      sentMessages: null,
      stock: null,
      messages: null
    },
    tabs: {
      dashboard: null,
      notifications: null,
      tasksAndProjects: null,
      workspace: null,
      stock3d: null,
      users: null,
      workers: null,
      clients: null,
      admin: null
    }
  },
  'models.menu.'
);
