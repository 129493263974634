export default {
  plural: 'Invitations',
  accept: 'Accept',
  acceptAll: 'Accept all',
  acceptSelected: 'Accept selected',
  decline: 'Decline',
  declineAll: 'Decline all',
  declineSelected: 'Decline selected',
  search: 'Search invitations by project name',
  areTouSureYouWantToAcceptAllProjectInvitationsThisActionCannotBeUndone:
    'Are you sure you want to accept all project invitations? This action cannot be undone.',
  areTouSureYouWantToDeclineAllProjectInvitationsThisActionCannotBeUndone:
    'Are you sure you want to decline all project invitations? This action cannot be undone.',
  areYouSureYouWantToDeclineProjectInvitationThisActionCannotBeUndone:
    'Are you sure you want to decline project invitation? This action cannot be undone.'
};
