export default {
  archivizerAccount: 'Archivizer account',
  cancel: 'Cancel invoice',
  created: 'created invoice',
  creditCard: 'Credit card',
  date: 'Invoice date',
  id: 'Invoice ID',
  plural: 'Invoices',
  refreshStatus: 'Refresh status',
  send: 'Send invoice',
  singular: 'Invoice',
  messages: {
    areYouSureYouWantToCancelInvoice:
      'Are you sure you want to cancel invoice?',
    payoneerWarning:
      'By using the Payoneer Services through the Online Pages and APIs, I agree that any information and data related to payments made through the API and any information provided by me on the Online Pages will be transmitted and processed by Payoneer, and may also be available to Company.'
  },
  noPlural: 'No invoices',
  account: 'Status',
  payer: 'Payer',
  payBill: 'Pay bill',
  projectName: 'Project name',
  sender: 'Sender',
  status: 'Invoice status'
};
