import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface BillsKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
  attach: LocalesKey;
  create: LocalesKey;
  amount: LocalesKey;
  pay: LocalesKey;
  client: LocalesKey;
  system: LocalesKey;
  unpaid: LocalesKey;
  types: {
    payoneer: LocalesKey;
    payoneerCheckout: LocalesKey;
    twoCheckout: LocalesKey;
    fondy: LocalesKey;
    stripe: LocalesKey;
    easypay: LocalesKey;
    invoice: LocalesKey;
    system: LocalesKey;
    debitAccount: LocalesKey;
    cancelDebitAccount: LocalesKey;
    bonus: LocalesKey;
    cancelBonus: LocalesKey;
    task: LocalesKey;
    cancelTask: LocalesKey;
    amountBill: LocalesKey;
    bankAccountBill: LocalesKey;
    bonusBill: LocalesKey;
    clientAccountBill: LocalesKey;
    debitAccountBill: LocalesKey;
    easypayBill: LocalesKey;
    ewayBill: LocalesKey;
    fondyBill: LocalesKey;
    interkassaBill: LocalesKey;
    invoiceBill: LocalesKey;
    liqpayBill: LocalesKey;
    payoneerBill: LocalesKey;
    raiffeisenBankBill: LocalesKey;
    rietumuBill: LocalesKey;
    stripeBill: LocalesKey;
    systemAccountBill: LocalesKey;
    taskBill: LocalesKey;
    twoCheckoutBill: LocalesKey;
    cancelAmountBill: LocalesKey;
    cancelBonusBill: LocalesKey;
    cancelDebitAccountBill: LocalesKey;
    cancelPaymentBill: LocalesKey;
    cancelTaskBill: LocalesKey;
  };
  statuses: {
    canceled: LocalesKey;
    notPaid: LocalesKey;
    paid: LocalesKey;
    paying: LocalesKey;
  };
  buttonTypes: {
    card: LocalesKey;
    account: LocalesKey;
    cardAccount: LocalesKey;
  };
  invoiceHasBeenSuccessfullyPaid: LocalesKey;
  payoneerCheckoutCancel: LocalesKey;
  paymentFailed: LocalesKey;
  paymentSuccessful: LocalesKey;
  paymentProcessing: LocalesKey;
  searchByProjectName: LocalesKey;
  selectAccount: LocalesKey;
  selectCurrency: LocalesKey;
  selectSource: LocalesKey;
  selectButtons: LocalesKey;
  payBills: LocalesKey;
  totalAmount: LocalesKey;
  totalUnpaidAmount: LocalesKey;
  transactionHasFailedDueToSome: LocalesKey;
}

export const billsKeys = keyPathMirror<BillsKeyType, string>(
  {
    plural: null,
    singular: null,
    attach: null,
    create: null,
    amount: null,
    pay: null,
    client: null,
    system: null,
    unpaid: null,
    types: {
      payoneer: null,
      payoneerCheckout: null,
      twoCheckout: null,
      fondy: null,
      stripe: null,
      easypay: null,
      invoice: null,
      system: null,
      debitAccount: null,
      cancelDebitAccount: null,
      bonus: null,
      cancelBonus: null,
      task: null,
      cancelTask: null,
      amountBill: null,
      bankAccountBill: null,
      bonusBill: null,
      clientAccountBill: null,
      debitAccountBill: null,
      easypayBill: null,
      ewayBill: null,
      fondyBill: null,
      interkassaBill: null,
      invoiceBill: null,
      liqpayBill: null,
      payoneerBill: null,
      raiffeisenBankBill: null,
      rietumuBill: null,
      stripeBill: null,
      systemAccountBill: null,
      taskBill: null,
      twoCheckoutBill: null,
      cancelAmountBill: null,
      cancelBonusBill: null,
      cancelDebitAccountBill: null,
      cancelPaymentBill: null,
      cancelTaskBill: null
    },
    statuses: {
      canceled: null,
      notPaid: null,
      paid: null,
      paying: null
    },
    buttonTypes: {
      card: null,
      account: null,
      cardAccount: null
    },
    invoiceHasBeenSuccessfullyPaid: null,
    payoneerCheckoutCancel: null,
    paymentFailed: null,
    paymentProcessing: null,
    paymentSuccessful: null,
    searchByProjectName: null,
    selectAccount: null,
    selectCurrency: null,
    selectSource: null,
    selectButtons: null,
    payBills: null,
    totalAmount: null,
    totalUnpaidAmount: null,
    transactionHasFailedDueToSome: null
  },
  'models.bills.'
);
