import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';

import { Files } from '../../../../../utils/Files';

import { UserMenuProfileInfoMoneyWithTooltip } from './helpers/UserMenuProfileInfoMoneyWithTooltip';

import { Icon } from '../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../helpers/ImageHelper';

import { useUser } from '../../../../../main/users/hooks/useUser';

import {
  FetchUserMenuQueryResponse,
  FETCH_USER_MENU_QUERY
} from '../../../../../main/users/queries/fetchUserMenu.query';

import { UserCache } from '../../../../../main/users/UserCache';
import { UserNanoID } from '../../../../../main/users/usersTypes';
import { UsersPermissions } from '../../../../../main/users/usersConstants';

import { profilesKeys } from '../../../../../locales/keys';

interface UserMenuProfileInfoProps {
  userNanoId: UserNanoID;
}

function UserMenuProfileInfo({ userNanoId }: UserMenuProfileInfoProps) {
  const { user, userFetched, userError } = useUser<FetchUserMenuQueryResponse>({
    cacheKey: UserCache.showMenuCacheKey(),
    query: FETCH_USER_MENU_QUERY,
    uuid: userNanoId
  });

  useShowToastOnErrorChange({ error: userError });

  const profileBalance = user?.client
    ? user?.clientBalance
    : user?.workerBalance;

  const profileDebt = user?.client ? user?.clientDebt : user?.debt;

  if (!user) {
    return null;
  }

  return (
    <LoadingSkeleton loaded={userFetched}>
      <div className="py-1 px-2">
        <div className="flex space-x-3">
          {user.image?.file && Files.isImage(user.image?.file) ? (
            <ImageHelper
              className="h-12 w-12 rounded-md"
              src={user.image.file}
              alt={user.fullName}
              version={ImageItemImageVersions.BigThumb}
            />
          ) : (
            <Icon
              icon={IconsEnum.PHOTOGRAPH_OUTLINE}
              className="h-12 w-12 rounded-md"
            />
          )}
          <div className="leading-snug pr-1 pt-1">
            <div className="text-md sm:text-base font-medium break-wrap">
              {user.fullName}
            </div>
            <div className="text-xs text-gray-500 dark:text-gray-400 break-wrap">
              {user.email}
            </div>
          </div>
        </div>
        <div className="bg-gray-200 dark:bg-gray-800 flex mt-2 py-1.5 rounded-md">
          <div className="border-gray-400 border-r dark:border-gray-700 text-center w-12">
            {getCurrencySymbol(user.currency)}
          </div>
          <div className="flex flex-1 items-center justify-evenly px-3 space-x-2">
            <div className="font-medium text-md">
              <UserMenuProfileInfoMoneyWithTooltip
                value={profileBalance}
                tooltipI18nText={profilesKeys.balance}
              />
            </div>
            <CheckPermissions
              action={UsersPermissions.READ_USER_MENU_USER_DEBT_FIELD}
            >
              <div className="text-2xs text-red-500">
                <UserMenuProfileInfoMoneyWithTooltip
                  value={profileDebt}
                  tooltipI18nText={profilesKeys.debt}
                />
              </div>
            </CheckPermissions>
          </div>
        </div>
      </div>
    </LoadingSkeleton>
  );
}

export default UserMenuProfileInfo;
