const basePath = '/auth';

export class AuthPath {
  static login() {
    return `${basePath}/login`;
  }

  static register() {
    return `${basePath}/register`;
  }

  static forgot() {
    return `${basePath}/forgot`;
  }
}
