import { useQuery } from 'react-query';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  FetchItemCacheKey,
  FetchItemGqlQuery,
  NanoID,
  UUID
} from '../../../../../../types';

import { fetchItem } from '../baseActions/fetchItem';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

import { ShowQueryOpts, ShowQueryErrorType } from './useShowQuery.types';

interface ShowQueryOptions<ResponseType> {
  cacheKey: FetchItemCacheKey;
  itemKey: string;
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
  placeholderData?: ResponseType;
  options: ShowQueryOpts<ResponseType>;
}

function useShowQuery<ResponseType, ItemType>({
  cacheKey,
  itemKey,
  query,
  uuid,
  placeholderData,
  options = {}
}: ShowQueryOptions<ResponseType>) {
  const localForageCacheKey = `${cacheKey}-${uuid}`;

  const {
    data: localForagePlaceholderData,
    isFetched: placeholderDataFetched
  } = useQuery<ResponseType | null>(
    `${localForageCacheKey}-placeholder`,
    () => LocalForage.getItem<ResponseType>(localForageCacheKey),
    {
      enabled: options.enabledPlaceholder,
      onSuccess: (data) => options.onSuccess?.(data)
    }
  );

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    ResponseType,
    ShowQueryErrorType
  >(
    [cacheKey, uuid],
    () =>
      fetchItem({
        query,
        uuid
      }),
    {
      cacheTime: options.cacheTime,
      enabled: options.enabled || placeholderDataFetched,
      placeholderData:
        placeholderData || localForagePlaceholderData || undefined,
      onSettled: options.onSettled,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        return LocalForage.setItem<ResponseType>(localForageCacheKey, data);
      },
      onError: options.onError
    }
  );

  // const updateItemCache = useShowQueryUpdateItemCache<ResponseType, ItemType>({
  //   fullCacheKey,
  //   itemKey
  // });

  const item: ItemType | null = data?.[itemKey] || null;

  return {
    item,
    itemErrorData: error,
    itemError: parseRequestError(error),
    itemFetched: isFetched,
    itemLoading: isLoading,
    itemIsPlaceholderData: isPlaceholderData
    // updateItemCache
  };
}

export default useShowQuery;
