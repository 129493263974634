export default {
  areYouSureYouWantToCancelTheTask: 'Ви впевнені, що хочете скасувати задачу?',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'Оцінюючи завдання, ви приймаєте результати завдання для клієнта',
  pleaseProvideYourAddress: 'Будь ласка, надайте вашу адресу',
  pleaseRateTheTask: 'Оцініть задачу',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'Платіж прийнято в обробку. Зазвичай платіж обробляється і зараховується, або скасовується платіжною системою/банком, який випустив карту протягом 10 - 30 хвилин. У цей час не потрібно робити нових спроб оплати.',
  thePaymentHasBeenCanceled: 'Платіж скасовано.',
  youWillBeRedirectedToTheProjectPageIn:
    'Вас буде направлено на сторінку проекту через',
  youWillBeRedirectedToThePaymentsPageIn:
    'Вас буде направлено на сторінку оплати через',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Ви впевнені, що хочете видалити цей запис? Цю дію неможливо скасувати.',
  thisActionWillAffect: 'Ця дія вплине на'
};
