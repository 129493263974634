import React, { useCallback, ForwardedRef, Fragment } from 'react';
import cl from 'classnames';

import { PureButtonHelper } from '../../../../buttons/PureButtonHelper';
import { PureRefButtonHelper } from '../../../../buttons/PureRefButtonHelper';
import { PureRefIconButtonHelper } from '../../../../buttons/PureRefIconButtonHelper';

import { words } from '../../../../../locales/keys';

import { SimpleModalCommonProps } from './../../SimpleModal.types';

interface SimpleModalButtonsProps {
  handleSubmit?: () => void;
  hideModal: () => void;
}

const SimpleModalButtons = React.forwardRef<
  HTMLButtonElement,
  SimpleModalButtonsProps & SimpleModalCommonProps
>(
  (
    componentProps: SimpleModalButtonsProps & SimpleModalCommonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      disabled,
      handleSubmit,
      hideModal,
      i18nCancelText = words.cancel,
      i18nSubmitText = words.save,
      i18nCloseText = words.close,
      submitIcon,
      submitClass,
      submitIconClassName,
      isLoading,
      submitDisabled,
      submitButtonColor = 'blue',
      withoutCancelButton = false,
      withCloseButton = false,
      withoutSubmitButton = false,
      onCancel
    } = componentProps;
    const handleCancel = useCallback<() => void>(() => {
      hideModal();
      onCancel?.();
    }, [onCancel, hideModal]);

    return (
      <div className="flex-shrink">
        <div className="flex p-4 space-x-2 justify-end">
          <Fragment>
            {withCloseButton && (
              <PureRefButtonHelper
                className="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                disabled={isLoading || disabled}
                i18nText={i18nCloseText}
                onClick={handleCancel}
                ref={ref}
              />
            )}
            {withoutCancelButton ? null : (
              <PureButtonHelper
                className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800"
                disabled={isLoading || disabled}
                i18nText={i18nCancelText}
                onClick={handleCancel}
              />
            )}
            {withoutSubmitButton ? null : (
              <Fragment>
                {submitIcon ? (
                  <PureRefIconButtonHelper
                    className={cl(
                      submitClass ||
                        'flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md',
                      {
                        'bg-red-600 hover:bg-red-700':
                          submitButtonColor === 'red',
                        'bg-blue-600 hover:bg-blue-700  ':
                          submitButtonColor === 'blue',
                        'bg-green-500 hover:bg-green-600':
                          submitButtonColor === 'green'
                      }
                    )}
                    disabled={isLoading || disabled || submitDisabled}
                    i18nText={isLoading ? words.processing : i18nSubmitText}
                    icon={submitIcon}
                    iconClassName={submitIconClassName}
                    ref={ref}
                    onClick={handleSubmit}
                  />
                ) : (
                  <PureRefButtonHelper
                    className={cl(
                      submitClass ||
                        'flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md',
                      {
                        'bg-red-600 hover:bg-red-700':
                          submitButtonColor === 'red',
                        'bg-blue-600 hover:bg-blue-700  ':
                          submitButtonColor === 'blue',
                        'bg-green-500 hover:bg-green-600':
                          submitButtonColor === 'green'
                      }
                    )}
                    disabled={isLoading || disabled || submitDisabled}
                    i18nText={isLoading ? words.processing : i18nSubmitText}
                    ref={ref}
                    onClick={handleSubmit}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    );
  }
);

SimpleModalButtons.displayName = 'SimpleModalButtons';

export default SimpleModalButtons;
