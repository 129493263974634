import cl from 'classnames';

import { UserMenuTypes } from '../../UserMenu.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth/useAuth';

import { ImageItemImageVersions } from '../../../../../helpers/ImageHelper';

import { Files } from '../../../../../utils/Files';

interface UserMenuAvatarProps {
  type: UserMenuTypes;
}

function UserMenuAvatar({ type }: UserMenuAvatarProps) {
  const currentUser = useCurrentUser();

  return (
    <img
      className={cl(
        'rounded-full',
        type === UserMenuTypes.MOBILE ? 'h-8 w-8' : 'h-10 w-10'
      )}
      src={Files.image(
        currentUser.get('image'),
        ImageItemImageVersions.Thumb150x150
      )}
      title={currentUser.get('fullName')}
      alt={currentUser.get('fullName')}
    />
  );
}

export default UserMenuAvatar;
