import React, { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import includes from 'lodash/includes';

import { AuthAppLayout } from '../AuthAppLayout';
import { MainAppLayout } from '../MainAppLayout';

import { AuthPath } from '../../../auth/AuthPath';

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
  const router = useRouter();

  const handleLogoutUser = useCallback<() => void>(
    () => router.push(AuthPath.login()),
    [router]
  );

  if (
    includes(router.pathname, '/auth') ||
    includes(router.pathname, '/register')
  ) {
    return <AuthAppLayout>{children}</AuthAppLayout>;
  }

  return (
    <MainAppLayout onLogoutUser={handleLogoutUser}>{children}</MainAppLayout>
  );
}

export default AppLayout;
