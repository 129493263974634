export default {
  action: 'Дія',
  android: 'Android',
  blocked: 'Заблоковано',
  burning: 'Невідкладне',
  colorTag: 'Тег кольору',
  createdAt: 'Створено',
  danger: 'Небезпека',
  description: 'Опис',
  financeRole: 'Фінансова роль',
  implementationDate: 'Термін виконання',
  implementedAt: 'Завершено',
  emailAddress: 'Електронна адреса',
  filterName: 'Назва фільтру',
  frontend: 'Frontend',
  fullName: "Повне ім'я",
  id: 'Id',
  ios: 'iOS',
  maxPrice: 'Макс вартість',
  minPrice: 'Мін вартість',
  body: 'Повідомлення',
  bodyEn: 'Повідомлення (En)',
  bodyUk: 'Повідомлення (Uk)',
  name: "Ім'я",
  nameEn: "Ім'я (En)",
  nameUk: "Ім'я (Uk)",
  nda: 'NDA',
  password: 'Пароль',
  price: 'Вартість',
  roles: 'Ролі',
  registrationDate: 'Дата реєстрації',
  sourceFilesCount: 'Робочі файли',
  started: 'Розпочато',
  status: 'Статус',
  tasksPrice: 'Вартість задач',
  title: 'Назва',
  type: 'Тип',
  uuid: 'UUID',
  user: 'Користувач'
};
