import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SettingsKeyType {
  financeInfo: LocalesKey;
  plural: LocalesKey;
  general: LocalesKey;
  system: LocalesKey;
  notification: LocalesKey;
}

export const settingsKeys = keyPathMirror<SettingsKeyType, string>(
  {
    financeInfo: null,
    plural: null,
    general: null,
    system: null,
    notification: null
  },
  'models.settings.'
);
