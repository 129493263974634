import { GraphQLClient } from 'graphql-request';

import { GRAPHQL_API_URI, UPLOADS_API_URI } from '../../../../../config';

export const graphQLClient = new GraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export const uploadsClient = new GraphQLClient(
  UPLOADS_API_URI || GRAPHQL_API_URI,
  {
    credentials: 'include',
    mode: 'cors'
  }
);
