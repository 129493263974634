export default {
  plural: 'Attachments',
  pluralGenitive: 'Attachments',
  pluralLowerCase: 'attachments',
  drawings: 'Drawings',
  download: 'Download',
  gridView: 'Grid view',
  file: 'File attachment',
  listView: 'List view',
  references: 'References',
  seeAll: 'See all attachments',
  textures: 'Textures'
};
