import '../lib/wdyr';

import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ErrorProps } from 'next/error';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastProvider } from 'react-toast-notifications';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import {
  GTM_TRACKING_ID,
  HOTJAR_ID,
  HOTJAR_SV,
  IS_PRODUCTION,
  VERSION
} from '../config';

import { AuthProvider } from '../auth/hooks/useAuth';

import { ThemeProvider } from '../app/hooks/useTheme';

import { AppLayout } from '../app/AppLayouts/AppLayout';

import { ToastContainer } from '../helpers/ToastContainer';

import { languageServerDetection } from '../utils/i18n';
import { setQueryLogger } from '../utils/setQueryLogger';

import '../styles/globals.scss';

const queryClient = new QueryClient();
setQueryLogger();

const tagManagerOptions = { gtmId: GTM_TRACKING_ID };
const hotJarId = parseInt(HOTJAR_ID, 10);
const hotJarSv = parseInt(HOTJAR_SV, 10);

function MyApp({ Component, pageProps, err }: AppProps & { err: ErrorProps }) {
  console.log(`Version: ${VERSION}`);
  useEffect(() => {
    if (IS_PRODUCTION) {
      TagManager.initialize(tagManagerOptions);
      hotjar.initialize(hotJarId, hotJarSv);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <ToastProvider placement="top-right" components={{ ToastContainer }}>
            <AppLayout>
              <Component {...pageProps} err={err} />

              <ReactQueryDevtools initialIsOpen={false} position="top-left" />
            </AppLayout>
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = ({ ctx }) => {
  languageServerDetection(ctx);

  return {};
};

export default MyApp;
