import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

const basePath = '/invoices';

export class BillPath {
  static index() {
    return basePath;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
