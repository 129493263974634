import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface InvoicesKeyType {
  archivizerAccount: LocalesKey;
  cancel: LocalesKey;
  created: LocalesKey;
  creditCard: LocalesKey;
  date: LocalesKey;
  id: LocalesKey;
  list: LocalesKey;
  plural: LocalesKey;
  refreshStatus: LocalesKey;
  send: LocalesKey;
  singular: LocalesKey;
  messages: {
    areYouSureYouWantToCancelInvoice: LocalesKey;
    payoneerWarning: LocalesKey;
  };
  noPlural: LocalesKey;
  account: LocalesKey;
  payer: LocalesKey;
  payBill: LocalesKey;
  projectName: LocalesKey;
  sender: LocalesKey;
  status: LocalesKey;
}

export const invoicesKeys = keyPathMirror<InvoicesKeyType, string>(
  {
    archivizerAccount: null,
    cancel: null,
    created: null,
    creditCard: null,
    date: null,
    id: null,
    list: null,
    plural: null,
    refreshStatus: null,
    send: null,
    singular: null,
    messages: {
      areYouSureYouWantToCancelInvoice: null,
      payoneerWarning: null
    },
    noPlural: null,
    account: null,
    payer: null,
    payBill: null,
    projectName: null,
    sender: null,
    status: null
  },
  'models.invoices.'
);
